import { Components } from '@mui/material/styles/components';
import { Theme } from '@mui/material';
import vars from '../scss/_vars.scss';

export const MuiTooltip: Components<Theme>['MuiTooltip'] = {
  defaultProps: {
    arrow: true,
  },
  styleOverrides: {
    tooltip: ({ theme }) => ({
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '19px',
      whiteSpace: 'pre-line',
      padding: '15px 16px',
      backgroundColor: '#fff',
      color: theme.palette.text.primary,
      boxShadow: '0px 16px 48px 0px #0000001A',
      '&>ul': {
        margin: '0 0 20px',
        paddingInlineStart: '20px',
      },

      '&::-webkit-scrollbar': {
        width: vars.scrollWidth,
      },

      '&::-webkit-scrollbar-thumb': {
        borderRadius: vars.scrollBorderRadius,
        backgroundColor: vars.scrollBackgroundColor,
      },
    }),
    tooltipArrow: {
      minWidth: '200px',
    },
    arrow: {
      color: '#fff',
    },
  },
};
