import {
  IconButton,
  Menu,
  MenuItem,
  ListItemText,
  Divider,
  ListItemIcon,
} from 'src/components/ui';
import { FC, useState, MouseEvent } from 'react';
import { Ellipsis } from 'src/assets/icons';
import { TDropdownListProps } from './types';

export const DropdownList: FC<TDropdownListProps> = ({
  items,
  withDivider = true,
  align = 'vertical',
  anchorOrigin = { vertical: 'bottom', horizontal: 'right' },
  transformOrigin = { vertical: 'top', horizontal: 'right' },
  ...rest
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const menuItemProps = {
    component: 'div',
  };

  const handleOpenMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleClick = (callback: (() => void) | undefined) => {
    if (!callback) return;

    handleCloseMenu();
    callback();
  };

  if (!items?.length) {
    return null;
  }

  return (
    <>
      <IconButton
        onClick={handleOpenMenu}
        sx={{
          transform: align === 'vertical' ? 'rotate(90deg)' : 'none',
        }}>
        <Ellipsis />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenu}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        {...rest}>
        {items.map((item, index) => (
          <li key={index}>
            {withDivider && index !== 0 && <Divider />}
            <MenuItem
              {...menuItemProps}
              onClick={() => handleClick(item.onClick)}>
              {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
              <ListItemText>{item.component}</ListItemText>
            </MenuItem>
          </li>
        ))}
      </Menu>
    </>
  );
};
