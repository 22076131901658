import { useEffect, useState, MouseEvent, useCallback } from 'react';
import { Popover } from '@mui/material';
import classNames from 'classnames';
import { DIRECTION, TSortItem } from 'src/components/ui/filters/types';

import { Button, IconButton, Typography } from 'src/components/ui';
import { ArrowLong, FiltersSortIcon } from 'src/assets/icons';
import styles from '../../filters.module.scss';

type TSortMenu<KeysSorting> = {
  listItems: Array<TSortItem<KeysSorting>>;
  onSort: (sortParameters: TSortItem<KeysSorting>) => void;
  noActive: boolean;
  isDisabled?: boolean;
  extraActions?: any;
  whiteVariant?: boolean;
};

export const SortMenu = <KeysSorting,>(props: TSortMenu<KeysSorting>) => {
  const {
    listItems,
    onSort,
    noActive,
    isDisabled,
    extraActions,
    whiteVariant,
  } = props;
  const defaultSortList = listItems;
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [listSort, setListSort] = useState(listItems);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const extraActionsComponent = extraActions ? extraActions(handleClose) : null;

  const handleChangeSort = (sortByItem: TSortItem<KeysSorting>) => {
    setAnchorEl(null);

    if (sortByItem.active && sortByItem.withoutDirection) {
      return;
    }

    let currentDirection = DIRECTION.ASC;

    if (sortByItem.active) {
      currentDirection =
        sortByItem.direction === DIRECTION.ASC ? DIRECTION.DESC : DIRECTION.ASC;
    }
    setListSort((prev) =>
      prev.map((item) => {
        if (sortByItem.key === item.key) {
          return {
            ...item,
            active: true,
            direction: DIRECTION[currentDirection],
          };
        }
        return { ...item, active: false, direction: DIRECTION.ASC };
      })
    );

    onSort({
      ...sortByItem,
      direction: currentDirection,
    });
  };
  const open = Boolean(anchorEl);
  const id = open ? 'sort-menu' : undefined;

  useEffect(() => {
    if (noActive) {
      setListSort(defaultSortList);
    }
  }, [noActive, defaultSortList]);

  return (
    <div>
      <IconButton
        disabled={!!isDisabled}
        aria-describedby={id}
        type="button"
        size="large"
        color={whiteVariant ? 'white' : 'secondary'}
        onClick={handleClick}>
        <FiltersSortIcon />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}>
        <div className={styles.popperMenu}>
          <Typography fontSize="18px" fontWeight="500" marginBottom="25px">
            Сортировка
          </Typography>
          {listSort.map((item) => (
            <Button
              key={item.key}
              variant="text"
              className={classNames(
                styles.itemSort,
                item.active && styles.active
              )}
              onClick={() => handleChangeSort(item)}>
              {item.label}
              {!item.withoutDirection && (
                <span
                  className={classNames(
                    !item.active && styles.hidden,
                    item.direction === DIRECTION.DESC && styles.desc
                  )}>
                  <ArrowLong />
                </span>
              )}
            </Button>
          ))}
          {extraActionsComponent?.actionButton}
        </div>
      </Popover>
      {extraActionsComponent?.actionDialog}
    </div>
  );
};
