import { toast } from 'react-toastify';
import { SvgIcon, Box, Typography } from 'src/components/ui';
import { ExclamationIcon } from 'src/assets/icons';
import vars from 'src/theme/scss/_vars.scss';
import { formatServerDate } from 'src/utils';

type TErrorNotification = (data: { title: string }) => void;

export const errorNotification: TErrorNotification = (data) => {
  if (!data) return;

  const { title } = data;

  toast(
    <Box display="flex" gap="16px">
      <div>
        <SvgIcon
          icon={ExclamationIcon}
          strokeHexColor={vars.warningText}
          width="25"
          height="25"
          iconPadding="8px"
          backgroundFill
        />
      </div>

      <Box
        display="flex"
        gap="8px"
        flexDirection="column"
        justifyContent="center">
        <Typography fontWeight="500" color="text.primary">
          {title}
        </Typography>

        <Typography color="secondary">
          {formatServerDate(new Date(), 'dd.MM.yyyy в HH:mm')}
        </Typography>
      </Box>
    </Box>,
    {
      closeButton: true,
      position: 'top-right',
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: false,
      progress: undefined,
    }
  );
};
