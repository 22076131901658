import { Stack, Typography } from '@mui/material';
import { IManagerBusinessRequest } from 'src/models';
import { InvokeExpertStage } from 'src/models/bids-details';

export const LogisticsInvocationInfoBlock = ({
  businessRequest,
}: {
  businessRequest: IManagerBusinessRequest;
}) => {
  if (
    !businessRequest.assignedSpecificRoles[
      InvokeExpertStage.LOGISTICS_IN_PROGRESS
    ] &&
    !businessRequest.assignedSpecificRoles[
      InvokeExpertStage.LOGISTICS_NEW_REQUEST
    ]
  ) {
    return null;
  }

  const logistics =
    businessRequest.assignedSpecificRoles[
      InvokeExpertStage.LOGISTICS_IN_PROGRESS
    ]?.[0];

  return (
    <Stack
      direction="column"
      gap="4px"
      sx={(theme) => ({
        borderRadius: '8px',
        padding: '12px',
        marginTop: '8px',
        background: theme.palette.error.light,
      })}>
      <Typography sx={(theme) => ({ color: theme.palette.text.secondary })}>
        На согласовании логистики
      </Typography>
      <Typography sx={(theme) => ({ color: theme.palette.text.primary })}>
        {logistics
          ? `${logistics.assignedManager?.lastName} ${
              logistics.assignedManager?.firstName
            } ${logistics.assignedManager?.middleName || ''}`
          : 'Все логисты'}
      </Typography>
    </Stack>
  );
};
