import { useEffect, useMemo, useState } from 'react';
import { Card, CircularProgress, Stack } from '@mui/material';
import { differenceInCalendarDays } from 'date-fns';
import {
  useGetRolesExcludedFromNotificationsQuery,
  useUpdateInvokedExpertRequestStatusMutation,
  useUpdateRulesExcludedFromNotificationsMutation,
} from 'src/api/business-request/business-request';
import { ChatIconCompany, DotIcon, Pencil } from 'src/assets/icons';
import { LogisticsInvocationInfoBlock } from 'src/components/layout/bids-layout/main-bids-layout/components/logistics-invocation-info-block';
import {
  Box,
  FormControlLabel,
  IconButton,
  Result,
  Skeleton,
  Switch,
  Typography,
} from 'src/components/ui';
import { PERMISSIONS, ROLES } from 'src/constants';
import {
  CompanyTypes,
  IManagerBusinessRequest,
  IStatusHistory,
  TVariantBusinessRequest,
} from 'src/models';
import { AuthService, RolePermissionService } from 'src/services';
import { getErrorMessage } from 'src/store/utils';
import { isAdmin, isManager, joinFullName, plural } from 'src/utils';
import { getBidStatusColor } from 'src/utils/bids';
import { TModeChangeManager } from '../header/components/assigned-managers';
import { ChangeManager } from '../header/components/assigned-managers/components';
import styles from './status-history-block.module.scss';

interface IStatusHistoryBlockProps {
  statusHistory?: IStatusHistory;
  isLoading: boolean;
  error: any;
  businessRequest: TVariantBusinessRequest;
  onClick: () => void;
}

export const StatusHistoryBlock = ({
  statusHistory,
  isLoading,
  error,
  businessRequest,
  onClick,
}: IStatusHistoryBlockProps) => {
  const isShowEditManagerForRequest = !businessRequest.orderProcessingStarted;
  const isShowEditManagerForOrder = RolePermissionService.can(
    PERMISSIONS.EDIT_MANAGER_ORDER
  );
  const [modeChangeManager, setModeChangeManager] =
    useState<TModeChangeManager>('kam');
  const [open, setOpen] = useState(false);

  const [updateInvokedExpertRequestStatus, { isLoading: isAssignmentLoading }] =
    useUpdateInvokedExpertRequestStatusMutation();

  const isUserAdmin = AuthService!.currentRole === ROLES.ADMIN;
  const isUserKAMOrKamPlus =
    AuthService!.currentRole === ROLES.KAM ||
    AuthService!.currentRole === ROLES.KAM_PLUS;
  const isAssignedKAMUserSpecificRole =
    AuthService!.isSpecificRole() &&
    businessRequest.assignedManagers?.kam?.id === AuthService.userId;
  const isAssignedKAMPlusUserSpecificRole =
    AuthService!.isSpecificRole() &&
    businessRequest.assignedManagers?.kamPlus?.id === AuthService.userId;

  const isUserCanChangeKAM =
    isAssignedKAMUserSpecificRole || isUserAdmin || isUserKAMOrKamPlus;

  const isUserCanChangeKAMPlus =
    isAssignedKAMPlusUserSpecificRole || isUserAdmin || isUserKAMOrKamPlus;

  const isShowEditTechExportForRequest =
    !businessRequest.orderProcessingStarted;
  const isUserCanChangeTechExpert =
    AuthService!.isSpecificRole() || isUserAdmin || isUserKAMOrKamPlus;

  const handleClose = () => {
    setModeChangeManager('kam');
    setOpen(false);
  };

  const handleOpenModal = (type: TModeChangeManager) => {
    setModeChangeManager(type);
    setOpen(true);
  };

  const nameKam = useMemo(
    () =>
      joinFullName(
        businessRequest.assignedManagers?.kam?.lastName,
        businessRequest.assignedManagers?.kam?.firstName,
        businessRequest.assignedManagers?.kam?.middleName
      ),
    [businessRequest]
  );

  const nameKamPlus = useMemo(
    () =>
      joinFullName(
        businessRequest.assignedManagers?.kamPlus?.lastName,
        businessRequest.assignedManagers?.kamPlus?.firstName,
        businessRequest.assignedManagers?.kamPlus?.middleName
      ),
    [businessRequest]
  );

  const nameTechExpert = useMemo(
    () =>
      joinFullName(
        businessRequest.assignedManagers?.techExpert?.lastName,
        businessRequest.assignedManagers?.techExpert?.firstName,
        businessRequest.assignedManagers?.techExpert?.middleName
      ),
    [businessRequest]
  );

  const passedLength = statusHistory?.passedStatuses.length || 0;
  const upcomingLength = statusHistory?.upcomingStatuses.length || 0;
  const listStatusHistoryLength = passedLength + upcomingLength;

  const currentStatusDaysCount = useMemo(() => {
    if (!statusHistory || !statusHistory?.passedStatuses.length) {
      return 0;
    }

    const currentStatus =
      statusHistory.passedStatuses[statusHistory.passedStatuses.length - 1];

    return Math.abs(
      differenceInCalendarDays(new Date(currentStatus.createdAt), new Date())
    );
  }, [statusHistory]);

  const currentDotIconColor = useMemo(
    () => getBidStatusColor(currentStatusDaysCount),
    [currentStatusDaysCount]
  );

  const handleStatusBlockClick = () => {
    if (
      !RolePermissionService.can(
        PERMISSIONS.VIEW_APPLICATION_CURRENT_STATUS_DAYS_COUNT
      )
    ) {
      return;
    }

    onClick();
  };

  const { data: rolesExcludedFromNotifications } =
    useGetRolesExcludedFromNotificationsQuery(
      {
        businessApplicationId: businessRequest?.businessApplicationId as number,
        businessRequestName: businessRequest?.name,
      },
      {
        skip: !businessRequest.businessApplicationId || !businessRequest.name,
      }
    );

  const [updateRolesExcludedFromNotifications] =
    useUpdateRulesExcludedFromNotificationsMutation();

  const [isNotificationsForCustomer, setIsNotificationsForCustomer] =
    useState(false);
  const [isNotificationsForContractor, setIsNotificationsForContractor] =
    useState(false);

  useEffect(() => {
    if (!rolesExcludedFromNotifications) {
      return;
    }

    setIsNotificationsForCustomer(
      !rolesExcludedFromNotifications?.excludedRolesFromNotifications.includes(
        CompanyTypes.CUSTOMER
      )
    );

    setIsNotificationsForContractor(
      !rolesExcludedFromNotifications?.excludedRolesFromNotifications.includes(
        CompanyTypes.CONTRACTOR
      )
    );
  }, [rolesExcludedFromNotifications]);

  const changeNotifications = (type: CompanyTypes) => {
    if (!businessRequest.businessApplicationId) {
      return;
    }

    const excluded = [] as CompanyTypes[];

    if (type === CompanyTypes.CUSTOMER) {
      if (!isNotificationsForContractor) {
        excluded.push(CompanyTypes.CONTRACTOR);
      }

      if (isNotificationsForCustomer) {
        excluded.push(CompanyTypes.CUSTOMER);
      }
    } else {
      if (!isNotificationsForCustomer) {
        excluded.push(CompanyTypes.CUSTOMER);
      }

      if (isNotificationsForContractor) {
        excluded.push(CompanyTypes.CONTRACTOR);
      }
    }

    const result = {
      businessApplicationId: businessRequest.businessApplicationId,
      excludedRolesFromNotifications: excluded,
    };

    updateRolesExcludedFromNotifications(result)
      .unwrap()
      .then(() => {
        if (type === CompanyTypes.CUSTOMER) {
          setIsNotificationsForCustomer((prev) => !prev);
        } else {
          setIsNotificationsForContractor((prev) => !prev);
        }
      })
      .catch(() => {});
  };

  const handleChangeNotificationForCustomer = () => {
    changeNotifications(CompanyTypes.CUSTOMER);
  };

  const handleChangeNotificationForContractor = () => {
    changeNotifications(CompanyTypes.CONTRACTOR);
  };

  if (isLoading) {
    return (
      <Skeleton width="430px" height="300px" sx={{ marginBottom: '24px' }} />
    );
  }

  if (error) {
    return (
      <Result
        noContentPadding
        className={styles.containerError}
        title={getErrorMessage(
          error,
          'Произошла ошибка при получении истории статусов'
        )}
      />
    );
  }

  return (
    <Card className={styles.wrapper}>
      <Card
        sx={{
          display: 'flex',
          padding: '12px',
          gap: '12px',
          alignItems: 'center',
          backgroundColor: '#F3F6FB',
          boxShadow: 'none',
          position: 'relative',
          cursor: RolePermissionService.can(
            PERMISSIONS.VIEW_APPLICATION_CURRENT_STATUS_DAYS_COUNT
          )
            ? 'pointer'
            : 'default',
        }}
        onClick={handleStatusBlockClick}>
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
          <CircularProgress
            variant="determinate"
            value={Math.round((passedLength / listStatusHistoryLength) * 100)}
            size="80px"
            className={styles.foreground}
          />
          <CircularProgress
            variant="determinate"
            value={100}
            size="80px"
            className={styles.background}
          />
          <Box
            sx={{
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '4px',
            }}>
            <Typography color="#292929" fontWeight="600">
              {passedLength}
            </Typography>
            <Typography variant="body2" color="#7A8694">
              {`из ${listStatusHistoryLength}`}
            </Typography>
          </Box>
        </Box>
        <Box>
          {RolePermissionService.can(
            PERMISSIONS.VIEW_APPLICATION_CURRENT_STATUS_DAYS_COUNT
          ) && (
            <Stack direction="row" gap="4px" alignItems="center">
              <DotIcon
                style={{
                  color: currentDotIconColor,
                }}
              />
              <Typography color="#7A8694" fontSize="10px">
                В текущем статусе:{' '}
                {plural(currentStatusDaysCount, '$d день', '$d дня', '$d дней')}
              </Typography>
            </Stack>
          )}
          {statusHistory?.passedStatuses && (
            <>
              <Typography color="#0041A0" fontWeight="500">
                {
                  statusHistory.passedStatuses[passedLength - 1].status
                    .displayName
                }
              </Typography>
              <Typography variant="body2" color="#7A8694">
                {
                  statusHistory.passedStatuses[passedLength - 1].status
                    .description
                }
              </Typography>
            </>
          )}
        </Box>
      </Card>

      {(AuthService.isSpecificRole() || isManager() || isAdmin()) && (
        <LogisticsInvocationInfoBlock
          businessRequest={businessRequest as IManagerBusinessRequest}
        />
      )}

      {RolePermissionService.can(
        PERMISSIONS.VIEW_APPLICATION_STATUS_DESCRIPTION_CARD
      ) && (
        <>
          {false && (
            <Card sx={{ marginTop: '12px', boxShadow: 'none' }}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{ padding: '12px', backgroundColor: '#FFE6E6' }}>
                <Stack gap="4px">
                  <Typography variant="body2" color="#7A8694">
                    На проверке техэкспертом с 01.10.2023:
                  </Typography>
                  <Typography color="#292929">
                    НЕ РЕАЛИЗОВАНО НА БЕКЕ
                  </Typography>
                </Stack>
                <IconButton className={styles.actionButton}>
                  <ChatIconCompany />
                </IconButton>
              </Stack>
            </Card>
          )}

          <Stack gap="12px" sx={{ marginTop: '12px' }}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between">
              <Stack>
                <Typography variant="body2" color="#7A8694">
                  Менеджер по заявке:
                </Typography>
                <Typography color="#292929">{nameKam}</Typography>
              </Stack>
              <Stack direction="row" gap="4px">
                <IconButton className={styles.actionButton}>
                  <ChatIconCompany />
                </IconButton>
                <IconButton
                  className={styles.actionButton}
                  disabled={!isShowEditManagerForRequest || !isUserCanChangeKAM}
                  onClick={() => handleOpenModal('kam')}>
                  <Pencil
                    sx={{
                      color:
                        !isShowEditManagerForRequest || !isUserCanChangeKAM
                          ? '#7A8694'
                          : '#292929',
                    }}
                  />
                </IconButton>
              </Stack>
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between">
              <Stack>
                <Typography variant="body2" color="#7A8694">
                  Менеджер по заказу:
                </Typography>
                <Typography color="#292929">{nameKamPlus || '-'}</Typography>
              </Stack>
              <Stack direction="row" gap="4px">
                <IconButton className={styles.actionButton}>
                  <ChatIconCompany />
                </IconButton>
                <IconButton
                  className={styles.actionButton}
                  disabled={
                    !isShowEditManagerForOrder || !isUserCanChangeKAMPlus
                  }
                  onClick={() => handleOpenModal('kam_plus')}>
                  <Pencil
                    sx={{
                      color:
                        !isShowEditManagerForOrder || !isUserCanChangeKAMPlus
                          ? '#7A8694'
                          : '#292929',
                    }}
                  />
                </IconButton>
              </Stack>
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between">
              <Stack>
                <Typography variant="body2" color="#7A8694">
                  Техэксперт:
                </Typography>
                <Typography color="#292929">{nameTechExpert || '-'}</Typography>
              </Stack>
              <Stack direction="row" gap="4px">
                <IconButton className={styles.actionButton}>
                  <ChatIconCompany />
                </IconButton>
                <IconButton
                  className={styles.actionButton}
                  disabled={
                    !isShowEditTechExportForRequest ||
                    !isUserCanChangeTechExpert
                  }
                  onClick={() => handleOpenModal('tech_expert')}>
                  <Pencil
                    sx={{
                      color:
                        !isShowEditTechExportForRequest ||
                        !isUserCanChangeTechExpert
                          ? '#7A8694'
                          : '#292929',
                    }}
                  />
                </IconButton>
              </Stack>
            </Stack>
          </Stack>

          {RolePermissionService.can(
            PERMISSIONS.VIEW_AND_EDIT_APPLICATION_EMAIL_SENDING_TOGGLE
          ) && (
            <Stack sx={{ marginTop: '4px', marginLeft: '-14px' }}>
              <FormControlLabel
                disabled={isLoading}
                control={
                  <Switch
                    checked={isNotificationsForCustomer}
                    onChange={handleChangeNotificationForCustomer}
                    sx={{ transform: 'scale(0.7, 0.7)', cursor: 'pointer' }}
                  />
                }
                label={
                  <Typography sx={{ marginLeft: '-4px' }}>
                    Рассылка для заказчика
                  </Typography>
                }
              />
              <FormControlLabel
                disabled={isLoading}
                sx={{ marginTop: '-8px' }}
                control={
                  <Switch
                    checked={isNotificationsForContractor}
                    onChange={handleChangeNotificationForContractor}
                    sx={{ transform: 'scale(0.7, 0.7)' }}
                  />
                }
                label={
                  <Typography sx={{ marginLeft: '-4px' }}>
                    Рассылка для исполнителя
                  </Typography>
                }
              />
            </Stack>
          )}
        </>
      )}

      {open && (
        <ChangeManager
          open={open}
          businessRequest={businessRequest as IManagerBusinessRequest}
          mode={modeChangeManager}
          close={handleClose}
        />
      )}
    </Card>
  );
};
