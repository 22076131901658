import { TContact } from 'src/components/pages/company/contacts/types';
import { ROLES } from 'src/constants';
import { TDocument } from 'src/models/document';
import { DIRECTION } from 'src/components/ui/filters/types';
import { TFile } from 'src/components/ui/file-upload';
import { TPhoneInputValue } from 'src/components/ui/phone-input';
import { TTechnicalOpportunity } from './technical-opportunity';
import { CompanyTypes, ICompany, MangerTypes } from './company';
import { ContractStatuses } from './contract';
import {
  ContractorStatusCode,
  CustomerStatusCode,
  IStatusHistory,
  KamStatusCode,
} from './bid';
import { ICargoDescriptions, TCargoParametersFull } from './cargo-parameters';

export enum BusinessRequestSource {
  WEBSITE = 'WEBSITE',
  EMAIL = 'EMAIL',
  TELEPHONY = 'TELEPHONY',
  WHATSAPP = 'WHATSAPP',
  EXPOSITION = 'EXPOSITION',
  TENDER = 'TENDER',
  MANUAL = 'MANUAL',
  ATTRACTION = 'ATTRACTION',
  HEAT = 'HEAT',
  CUSTOMER = 'CUSTOMER',
}

export enum BudgetChangeHistorySource {
  MANUAL = 'MANUAL',
  BEST_COST = 'BEST_COST',
  COST_FOR_BEST_CONTRACTOR_COMMERCIAL_OFFER = 'COST_FOR_BEST_CONTRACTOR_COMMERCIAL_OFFER',
  COST_FOR_LAST_CUSTOMER_COMMERCIAL_OFFER = 'COST_FOR_LAST_CUSTOMER_COMMERCIAL_OFFER',
  COST_FOR_APPROVED_CUSTOMER_COMMERCIAL_OFFER = 'COST_FOR_APPROVED_CUSTOMER_COMMERCIAL_OFFER',
  COST_FOR_CONTRACTOR_SPECIFICATION = 'COST_FOR_CONTRACTOR_SPECIFICATION',
  COST_FOR_CUSTOMER_SPECIFICATION = 'COST_FOR_CUSTOMER_SPECIFICATION',
}

export enum BusinessRepeatedType {
  NEW = 'NEW',
  REPEATED = 'REPEATED',
}

export enum InvokeExpertStage {
  REQUEST_FORMULATION = 'REQUEST_FORMULATION',
  REQUEST_AGREEMENT = 'REQUEST_AGREEMENT',
  QUOTATION_AGREEMENT = 'QUOTATION_AGREEMENT',
  SPECIFICATION_AGREEMENT = 'SPECIFICATION_AGREEMENT',
  REQUEST_QUESTIONS = 'REQUEST_QUESTIONS',
  QUOTATION_LOGISTICS_CALCULATION = 'QUOTATION_LOGISTICS_CALCULATION',
  SPECIFICATION_LOGISTICS_CALCULATION = 'SPECIFICATION_LOGISTICS_CALCULATION',
  LOGISTICS_NEW_REQUEST = 'LOGISTICS_NEW_REQUEST',
  LOGISTICS_IN_PROGRESS = 'LOGISTICS_IN_PROGRESS',
  LOGISTICS_COST_PROVIDED = 'LOGISTICS_COST_PROVIDED',
  SPECIFICATION_NEW_REQUEST = 'SPECIFICATION_NEW_REQUEST',
  SPECIFICATION_FORMING = 'SPECIFICATION_FORMING',
  SPECIFICATION_PROVIDED = 'SPECIFICATION_PROVIDED',
}

export enum InvokeExpertStatus {
  IN_REVIEW = 'IN_REVIEW',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  FORMING = 'FORMING',
  FORMED = 'FORMED',
}

interface ICommonBusinessRequest<T = string> {
  createdAt: string;
  name: string;
  amocrmNumber: string;
  amocrmId: number;
  color: string | null;

  businessApplicationId?: number;
  businessRequestId?: number;
  contractorRequestId?: number;

  status: {
    code: T;
    displayName?: string;
    description?: string;
  };

  refusal: {
    id: number;
    createdAt: string;
    comment?: string;
    cause?: string;
    internalCause?: string;
    internalComment?: string;
    customerCause?: string;
    customerComment?: string;
    contractorCause?: string;
    contractorComment?: string;
  } | null;

  assignedManagers?: {
    [key in MangerTypes]?: {
      id: number;
      login: string;
      lastName: string;
      firstName: string;
      middleName: string;
      position: string;
      contactInfoDto: {
        phones: TPhoneInputValue[];
      };
    };
  };
}

export interface IBidsCustomerContact extends TContact {}

export interface IBudgetChangeHistory {
  budget: number;
  source: BudgetChangeHistorySource;
  createdAt: string;
}

export interface IBusinessRequestCustomerInfo
  extends Omit<ICommonBusinessRequest, 'status'> {
  businessRequestId: number;
  organizationId: number;
  organizationName: string;
  deliveryAddress: string;
  contacts: IBidsCustomerContact[];
  assignedManagers: ICompany['assignedManagers'];
  businessApplicationDescription: string;
  businessApplicationSource: BusinessRequestSource;
  businessApplicationBudget: number;
  documents: {
    BUSINESS_APPLICATION?: TFile[];
  };
  tenderDate: string;
  saleDate: string;
  repeatedType: string;
  budgetChangeHistory: IBudgetChangeHistory[];
}

export interface IUpdateBusinessRequestInfo {
  name: string;
  description: string;
  source: BusinessRequestSource;
  saleDate: Date | null;
  tenderDate: Date | null;
  budget: number;
}

export interface ICustomerBusinessRequest
  extends ICommonBusinessRequest<CustomerStatusCode> {
  id: number;
  businessRequestId: number;
  businessApplicationId: number;
  commentForContractor: string;
  documents: {
    [key in BusinessRequestDocumentType]: TDocument[];
  };
  orderProcessingStarted: boolean;

  customerBusinessApplicationRefusalRequest: {
    id?: number;
    createdAt: string;
    comment: string;
    cause: string;
  } | null;

  description: string;
  deliveryAddress: string;
  saleDate: string | null;
}

export interface IContractorRequest
  extends ICommonBusinessRequest<ContractorStatusCode>,
    ICommonRequestData<ContractorStatusCode> {
  contractorRequestId: number;
  canBeProlonged: undefined;
  businessRequestId: number;
  allContractorsAllowed: undefined;
  orderProcessingStarted: boolean;
  businessApplicationId: number;

  businessRequestStatus: {
    code: KamStatusCode;
    displayName?: string;
    description?: string;
  };

  auction: {
    auctionInfo: {
      startAt: string | null;
      endAt: string | null;
    } | null;

    bestCommercialOfferCost: number | null;
  } | null;

  rebidding: {
    rebiddingInfo: {
      endAt: string;
    } | null;
  } | null;

  exclusion: {
    cause: string;
    createdAt: string;
  } | null;
}

export interface IContractorBusinessRequest
  extends Omit<
    IContractorRequest,
    'contractorRequestId' | 'status' | 'refusal'
  > {
  contractorRequestId?: number;
  businessRequestId: number;
  status?: never;
  refusal?: never;

  statusHistory: IStatusHistory['passedStatuses'][number];
}

export type TVariantBusinessRequest =
  | IManagerBusinessRequest
  | ICustomerBusinessRequest
  | IContractorRequest
  | IContractorBusinessRequest;

export enum BusinessRequestDocumentType {
  REQUEST_FOR_CONTRACTORS = 'REQUEST_FOR_CONTRACTORS',
  BUSINESS_APPLICATION = 'BUSINESS_APPLICATION',
}

export enum LogisticsListType {
  PHONE_CALL = 'phone-call',
  EMAIL_MESSAGE = 'message-email',
  CHAT = 'chat',
  NOTE = 'note',
}

export interface ILogisticsList {
  id: number;
  type: LogisticsListType;
  author: string;
  date: string;
  label: string;
}

export interface ICommonRequestData<T = string>
  extends ICommonBusinessRequest<T> {
  deliveryAddress: string;
  commentForContractor: string;
  commercialOfferDueDate: string;
  implementationStartDate: string;
  implementationEndDate: string;
  technicalOpportunitiesCategories: TTechnicalOpportunity[];
  documents: {
    [key in BusinessRequestDocumentType]?: TDocument[];
  };
  canBeProlonged?: boolean;
  deliveryParameters?: TCargoParametersFull | null;
  allContractorsAllowed?: boolean;
  orderProcessingStarted?: boolean;
}

export interface IManagerBusinessRequest
  extends ICommonRequestData<KamStatusCode> {
  deliveryAddressKladrId: string;
  canBeProlonged: boolean;
  allContractorsAllowed: boolean;
  businessRequestId: number;
  businessApplicationId: number;
  type: 'EXPECTED' | 'REAL';
  orderProcessingStarted: boolean;

  customerBusinessApplicationRefusalRequest: {
    id: number;
    createdAt: string;
    comment: string;
    cause: string;
  } | null;

  auction: {
    auctionInfo: {
      startAt: string | null;
      endAt: string | null;
    } | null;

    bestCommercialOfferCost: number | null;
  } | null;

  rebidding: {
    rebiddingInfo: {
      endAt: string;
    } | null;
  } | null;

  assignedManagers: ICompany['assignedManagers'];

  assignedSpecificRoles: Record<InvokeExpertStage, ISpecificRole[] | undefined>;
}

export interface ISpecificRole {
  approvalStatus: InvokeExpertStatus;
  role: ROLES;
  assignedManager?: {
    id: number;
    login: string;
    lastName: string;
    firstName: string;
    middleName: string;
    position: string;
    contactInfoDto: {
      phones: TPhoneInputValue[];
    };
  };
}

export interface IRequestPerformersForm
  extends Pick<
    IManagerBusinessRequest,
    | 'commentForContractor'
    | 'canBeProlonged'
    | 'allContractorsAllowed'
    | 'type'
    | 'deliveryAddress'
    | 'technicalOpportunitiesCategories'
  > {
  commercialOfferDueDate: Date | null;
  implementationStartDate: Date | null;
  implementationEndDate: Date | null;
  documents: {
    REQUEST_FOR_CONTRACTORS: TFile[];
  };
  cargoDescriptions: ICargoDescriptions;
}

export interface Performer {
  organizationId: number;
  organizationName: string;
  contractId: number;
  contractStatus: ContractStatuses;
  organizationStatus: ICompany['organizationStatus'];
  mainPresentationId: number | null;

  delivery: {
    region: string;
    city: string | null;
    shippingAddressKladrId?: string | null;

    distanceToCustomer: number | null;
    distanceToCustomerErrorMessage: string | null;

    shippingCost: number | null;
    shippingCostErrorMessage: string | null;
  };

  technicalOpportunitiesCategories: TTechnicalOpportunity[];
}

export interface IBusinessRequestSearchPerformers {
  totalCount: number;
  page: number;

  items: Performer[];
}

export interface IBusinessRequestSearchPerformersDto {
  id: number;

  body: {
    searchQuery: string;
    sort: {
      field: 'DISTANCE';
      order: DIRECTION;
    };
    paging: {
      page: number;
      limit: number;
    };
    technicalOpportunitiesCategories: TTechnicalOpportunity[];
  };
}

export interface IAssignExpertData {
  requestId: number;
  requestStatus: InvokeExpertStage;
  role: ROLES;
  approvalStatus?: InvokeExpertStatus;
  commercialOfferId?: number;
  customerSpecificationId?: number;
  contractorSpecificationId?: number;
  logisticsCalculationRequest?: LogisticsCalculationRequest;
}

export interface LogisticsCalculationDto {
  id?: number;
  vehicleType: string;
  costPerUnit: number;
  quantity: number;
  totalCost: number;
  internalComment: string;
  commentForKam: string;
}

export interface LogisticsCalculationRequest {
  deliveryAddress?: string;
  organizationId?: number;
  shipmentAddress?: string;
  cargoDescription?: string;
  cargoParameters?: string;
  organizationName?: string;
  comment?: string;
  documents?: TDocument[];
}

export interface IBusinessRequestSetContractorRequestDto {
  id: number;

  body: {
    organizationIds: number[];
  };
}

export interface IBusinessRequestListPerformers {
  totalCount: number;
  page: number;

  countByCriteria: {
    allOnPlatformCount: number;
    commercialOfferReceivedCount: number;
    inProgressCount: number;
    invitedCount: number;
    refusedCount: number;
    unclaimedCount: number;
  };

  items: {
    lastActivityDate: string;
    contractorRequestId: number;
    organizationId: number;
    organizationName: string;
    contractId: number;
    contractStatus: ContractStatuses;
    organizationStatus: ICompany['organizationStatus'];
    mainPresentationId: null;

    delivery: {
      region: string;
      city: string | null;
      shippingAddressKladrId?: null;

      distanceToCustomer: number | null;
      distanceToCustomerErrorMessage: string | null;

      shippingCost: number | null;
      shippingCostErrorMessage: string | null;
    };

    exclusion: {
      cause: string;
      createdAt: string;
    } | null;

    refusal: {
      id: number;
      cause: string;
      createdAt: string;
      comment: string;
    } | null;

    currentStatus: {
      code: ContractorRequestStatusForKam;
      displayName: string;
      createdAt: string;
    };

    statusHistory: {
      code: ContractorRequestStatusForKam;
      displayName: string;
      createdAt: string | null;
    }[];

    technicalOpportunitiesCategories: TTechnicalOpportunity[];
    rebiddingContractorRequestId?: number;
  }[];
}

export enum OptionsListPerformers {
  ALL = 'ALL',
  INVITED = 'INVITED',
  ALL_ON_PLATFORM = 'ALL_ON_PLATFORM',
  UNCLAIMED = 'UNCLAIMED',
  IN_PROGRESS = 'IN_PROGRESS',
  COMMERCIAL_OFFER_RECEIVED = 'COMMERCIAL_OFFER_RECEIVED',
  REFUSED = 'REFUSED',
}

export interface IBusinessRequestListPerformersDto {
  id: string;

  body: {
    searchQuery: string;

    sort: {
      field: 'DISTANCE';
      order: DIRECTION;
    };

    filter: {
      criterion: OptionsListPerformers;
    };

    paging: {
      page: number;
      limit: number;
    };

    technicalOpportunitiesCategories: TTechnicalOpportunity[];
  };
}

export interface IBusinessRequestDeletePerformersDto {
  contractorRequestId: number;
  cause: string;
}

export enum ContractorRequestStatusForKam {
  REQUEST_SENT = 'REQUEST_SENT',
  REQUEST_VIEWED = 'REQUEST_VIEWED',
  IN_PROGRESS = 'IN_PROGRESS',
  COMMERCIAL_OFFER_SENT = 'COMMERCIAL_OFFER_SENT',
  REFUSED = 'REFUSED',
  EXCLUDED = 'EXCLUDED',
}

export enum ContractorRequestStatusForKamPlus {
  CONTRACTOR_WITHOUT_CONTACT = 'CONTRACTOR_WITHOUT_CONTACT',
  NEW_CONTRACTOR = 'NEW_CONTRACTOR',
  PROPS_UPDATED = 'PROPS_UPDATED',
  ESK_ACQUIRED = 'ESK_ACQUIRED',
  SOB_CHECKUP_COMPLETED = 'SOB_CHECKUP_COMPLETED',
  CONTRACT_SENT = 'CONTRACT_SENT',
  DISAGREEMENT_PROTOCOL_RECEIVED = 'DISAGREEMENT_PROTOCOL_RECEIVED',
  PROTOCOL_RECEIVED = 'PROTOCOL_RECEIVED',
  CONTRACT_SIGNED = 'CONTRACT_SIGNED',
  // SIGNING_APPROVED = 'SIGNING_APPROVED',
  CONTRACTOR_SIGNED = 'CONTRACTOR_SIGNED',
  REFUSED = 'REFUSED',
}

export const ContractorRequestForKamPlusStatusName: Record<
  ContractorRequestStatusForKamPlus,
  string
> = {
  [ContractorRequestStatusForKamPlus.CONTRACTOR_WITHOUT_CONTACT]:
    'ИСПОЛНИТЕЛЬ БЕЗ КОНТАКТА',
  [ContractorRequestStatusForKamPlus.NEW_CONTRACTOR]: 'НОВЫЙ ИСПОЛНИТЕЛЬ',
  [ContractorRequestStatusForKamPlus.PROPS_UPDATED]:
    'ДАННЫЕ ИСПОЛНИТЕЛЯ ПОЛУЧЕНЫ',
  [ContractorRequestStatusForKamPlus.ESK_ACQUIRED]: 'ЕСК ПОЛУЧЕН',
  [ContractorRequestStatusForKamPlus.SOB_CHECKUP_COMPLETED]:
    'ПРОВЕРКА СОБ ПРОЙДЕНА',
  [ContractorRequestStatusForKamPlus.CONTRACT_SENT]:
    'ПРОЕКТ ДОГОВОРА ОТПРАВЛЕН',
  [ContractorRequestStatusForKamPlus.DISAGREEMENT_PROTOCOL_RECEIVED]:
    'Протокол разногласий получен',
  [ContractorRequestStatusForKamPlus.PROTOCOL_RECEIVED]: 'ПРОТОКОЛ ПОЛУЧЕН',
  [ContractorRequestStatusForKamPlus.CONTRACT_SIGNED]: 'ДОГОВОР ПОДПИСАН',
  // [ContractorRequestStatusForKamPlus.SIGNING_APPROVED]:
  //   'ПОДКЛЮЧЕНИЕ ИСПОЛНИТЕЛЯ СОГЛАСОВАНО',
  [ContractorRequestStatusForKamPlus.CONTRACTOR_SIGNED]:
    'ИСПОЛНИТЕЛЬ ПОДКЛЮЧЕН',
  [ContractorRequestStatusForKamPlus.REFUSED]: 'ПОДКЛЮЧЕНИЕ ОТКЛОНЕНО',
};

export enum EBidStatusColor {
  'SUCCESS' = '#13b81a',
  'WARNING' = '#FF7400',
  'DANGER' = '#FF0000',
}

export interface IRolesExcludedFromNotificationsGetParams {
  businessApplicationId: number;
  businessRequestName: string;
}

export interface IRolesExcludedFromNotificationsResult {
  businessApplicationId: number;
  businessRequestIds: number[];
  excludedRolesFromNotifications: CompanyTypes[];
}

export interface IRolesExcludedFromNotificationsParams {
  businessApplicationId: number;
  excludedRolesFromNotifications: CompanyTypes[];
}

export interface IThereIsNoBestKpStatusParams {
  businessApplicationId: number;
  businessRequestId: number;
}

export interface IThereIsBestKpStatusParams {
  businessApplicationId: number;
  businessRequestId: number;
  contractorCommercialOfferId?: number;
}

export type TGetAllCompanyRequestsParams = {
  filter: {
    kamUserIds: string[];
    minBudget: string;
    maxBudget: string;
    status: string | undefined;
    firstStartDate: string;
    lastStartDate: string;
  };
  sort: { field: string; order: DIRECTION | string };
};
