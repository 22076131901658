import { FC, ReactNode } from 'react';
import { TTechnicalOpportunitiesCategories } from 'src/models';

export enum DIRECTION {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum FilterTypes {
  SEARCH = 'search',
  SORT = 'sort',
  FILTERS = 'filters',
  CUSTOM_TAG = 'customTag',
}

export type TTreeChipValue = {
  categoryId: number;
  subcategoryId?: number;
};

export type TChip = {
  type: FilterTypes;
  // Используется только при редактировании значений Чипов типа SEARCH
  onSearchChipEdit?: (oldValue: string, newValue: string) => void;
  updateEditChipRefValue?: (newValue: string | null) => void;
  editChipRefValue?: string | null;

  label: string;
  value?: string | string[] | TRangeFilterValue | number | TTreeChipValue;
  // Используется только в случае если type = 'filters'
  filterKey?: string;
  nameFilter?: string;
  direction?: DIRECTION;
  hidden: boolean;
};

export type TSortItem<KeysSorting> = {
  label: string;
  key: Extract<KeysSorting, string>;
  direction: DIRECTION;
  active: boolean;
  withoutDirection?: boolean;
};

export type TSort<KeysSorting> = {
  field: KeysSorting;
  direction: DIRECTION;
};

export type TFilterTab<T = ReactNode> = {
  label: T;
  key: string;
  active: boolean;
  tooltip?: string;
  nameFilter?: string;
  icon?: any;
};

export type TVariant = {
  value: string;
  label: string;
  checked: boolean;
  nestedVariants?: TVariant[];
};

export type TRangeFilterValue = {
  from: string;
  to: string;
};

export type TFilterValueField =
  | string
  | string[]
  | TRangeFilterValue
  | TTechnicalOpportunitiesCategories;

// Тип для работы с данными внутри фильтра
export type TFilterValue<FilterKeys> = {
  key: FilterKeys;
  // Как значение будет отображаться в интерфейсе (Chip)
  stringRepresentation: string | string[];
  value: TFilterValueField;
};

export type TFilterListMap<FilterKeys extends string = string> = Record<
  FilterKeys,
  TFilterItem<FilterKeys>
>;

export type TFilterListValuesMap<FilterKeys extends string = string> = Partial<
  Record<FilterKeys, TFilterValue<FilterKeys>>
>;

export type FilterFormType =
  | 'array'
  | 'date'
  | 'range'
  | 'tab'
  | 'tree'
  | 'toggle';

export type TFilterItemBaseProps<FilterKeys> = {
  filter?: TFilterValue<FilterKeys>;
  updateFilterValue: (value: TFilterValue<FilterKeys>) => void;
  filterKey: FilterKeys;
  variantsList: TVariant[];
  showAllVariantsList?: boolean;
  closeAllVariantsList?: () => void;
  allVariantsTitle?: string;
  defaultTabValue?: string;
};

export type TFilterItem<FilterKeys extends string = string> = {
  label: string;
  key: FilterKeys;
  // Это поле нужно только для валидации внутри FiltersPopup
  filterFormType: FilterFormType;
  render: (
    props: TFilterItemBaseProps<FilterKeys>
  ) => React.ReactElement<TFilterItemBaseProps<FilterKeys>>;
  // Передается не напрямую в компонент, т.к. нужно при инициализации фильтров из queryParams
  variantsList?: TVariant[];
  defaultTabValue?: string;
  tabsData?: Record<string, { count: number; tooltip?: string }>;
  showFilter?: boolean;
};

export type FiltersAutocompleteProps<AutocompleteItemType> = {
  getOptionLabel: (option: AutocompleteItemType) => string;
  renderOption: (
    props: object,
    option: AutocompleteItemType
  ) => React.ReactNode;
  fetchApi: (value: string) => Promise<AutocompleteItemType[]>;
};

export type FiltersProps<
  KeysSorting,
  AutocompleteItemType = {},
  FilterKeys extends string = string
> = {
  onSearch?: (searchQueries: string[]) => void;
  onSort?: (sortParameters: TSort<KeysSorting> | null) => void;
  searchQueries?: string[];
  sortList?: Array<TSortItem<KeysSorting>>;
  tabList?: Array<TFilterTab>;
  rightBlock?: FC | React.ReactNode;
  autocompleteOptions?: FiltersAutocompleteProps<AutocompleteItemType>;
  filtersData?: TFilterListValuesMap<FilterKeys>;
  filterVariantsList?: TFilterListMap<FilterKeys>;
  onFilter?: (filters: TFilterListValuesMap<FilterKeys>) => void;
  // Нужно передать для сохранения фильтров в url
  queryParamsConfig?: {
    queryParams: Record<FilterKeys, any>;
    setQueryParams: (queryParams: Record<FilterKeys, any>) => void;
  };
  defaultInput?: boolean;
  isDisabled?: boolean;
  rightBlockWrapper?: boolean;
  searchInputClassnames?: string;
  defaultFiltersData?: TDefaultFilters;
  countSearchQuery?: number;
  resetTabs?: boolean;
  showFiltersPopover?: boolean;
  onCustomTagDelete?: (filter: TCustomTag) => void;
  onClearCustomTags?: VoidFunction;
  extraActions?: any;

  // Версия для отображения на сером фоне
  whiteVariant?: boolean;
};

export type TCustomTag = Pick<TChip, 'label' | 'value'>;

export type TDefaultFilters = {
  searchQueries?: Array<string>;
  filter?: Record<string, any>;
  sort?: {
    field: string;
    order: DIRECTION;
  };
  customTags?: Pick<TChip, 'label' | 'value'>[];
};
