import { useEffect, useMemo, useState } from 'react';
import { TabsSelect } from 'src/components/ui/index';
import {
  TFilterItemBaseProps,
  TFilterValue,
} from 'src/components/ui/new-filters/types';
import { TabLabel } from 'src/components/widgets';
import styles from './tab-filters.module.scss';

export type TabFiltersFormProps<FilterKeys extends string = string> = {
  filterKey: string;
  whiteVariant?: boolean;
  data?: Record<string, { count: number; tooltip?: string }>;
} & TFilterItemBaseProps<FilterKeys>;

export const TabFilters = <FilterKeys extends string = string>({
  variantsList,
  filterKey,
  filter,
  updateFilterValue,
  defaultTabValue,
  whiteVariant,
  data,
}: TabFiltersFormProps<FilterKeys>) => {
  const [currentVariantsList, setCurrentVariantsList] = useState(
    variantsList.filter((variant) => variant.label)
  );

  useEffect(() => {
    setCurrentVariantsList(variantsList.filter((variant) => variant.label));
  }, [variantsList]);

  useEffect(() => {
    if (variantsList.length === 0) {
      return;
    }

    if (filter) {
      const updatedVariantList = currentVariantsList.map((variantItem) => ({
        ...variantItem,
        checked: Boolean((filter.value as string) === variantItem.value),
      }));

      setCurrentVariantsList(updatedVariantList);
    } else {
      const updatedVariantList = currentVariantsList.map((variantItem) => ({
        ...variantItem,
        checked: Boolean(defaultTabValue === variantItem.value),
      }));

      setCurrentVariantsList(updatedVariantList);
    }
  }, [filter, variantsList]);

  const toggleSelectedVariant = (index: number) => {
    const selectedVariant = currentVariantsList[index];

    const updatedFilter: TFilterValue<FilterKeys> = {
      key: filterKey,
      value: selectedVariant.value,
      stringRepresentation: '',
    };

    updateFilterValue(updatedFilter);
  };

  const tabsOptions = useMemo(
    () =>
      currentVariantsList.map((variantItem) => ({
        ...variantItem,
        label: data?.[variantItem.value] ? (
          <TabLabel
            tab={{
              ...data[variantItem.value],
              label: !data[variantItem.value].tooltip
                ? `${variantItem.label} #{count}`
                : variantItem.label,
            }}
            count={data[variantItem.value].count}
          />
        ) : (
          variantItem.label
        ),
      })),
    [currentVariantsList]
  );

  return (
    <TabsSelect
      value={
        currentVariantsList.find((variant) => variant.checked)?.value || null
      }
      options={tabsOptions}
      onChange={(_, index) => toggleSelectedVariant(index)}
      className={whiteVariant ? styles.whiteTabs : styles.grayTabs}
      activeClassName={
        whiteVariant ? styles.whiteActiveTab : styles.grayActiveTab
      }
    />
  );
};
