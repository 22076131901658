import {
  CSSProperties,
  FormEvent,
  isValidElement,
  PropsWithChildren,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  FieldValues,
  FormProvider,
  SubmitHandler,
  SubmitErrorHandler,
  UseFormReturn,
  FieldPathValue,
  FieldPath,
} from 'react-hook-form';
import classNames from 'classnames';
import { Box, Button, ConfirmDialog } from 'src/components/ui';
import { SxProps, Theme } from '@mui/material';
import { FormList } from './form-list';
import { FormItem } from './form-item';
import styles from './form.module.scss';
import { FormError } from './components';
import { TButton } from '../button';

export type FormProps<T extends FieldValues = FieldValues> = PropsWithChildren<{
  form: UseFormReturn<T>;
  className?: string;
  onSubmit?: SubmitHandler<T>;
  onSubmitError?: SubmitErrorHandler<T>;
  onCancel?: () => void;
  loading?: boolean;
  preloading?: boolean;
  footer?: ReactNode;
  viewMode?: boolean;
  btnFollowScroll?: boolean;
  extraActions?: ReactNode;
  skipDirtyCheck?: boolean;
  showCancelConfirm?: boolean;
  showCancelButton?: boolean;
  cancelConfirmText?: string;
  cancelBtnText?: string;
  footerStyle?: CSSProperties;
  actionsSx?: SxProps<Theme>;
  submitButtonProps?: TButton & {
    text?: string;
  };
  autoFocusNext?: boolean;
  showSubmitButton?: boolean;
  stopPropagation?: boolean;
}>;

type TInputElement = HTMLElement & {
  type?: string;
};

const Form = <T extends FieldValues = FieldValues>({
  children,
  form,
  className,
  onSubmit,
  onSubmitError,
  onCancel,
  loading,
  preloading,
  footer,
  viewMode,
  btnFollowScroll = true,
  extraActions,
  skipDirtyCheck,
  showCancelConfirm = true,
  showCancelButton = true,
  cancelConfirmText = 'После отмены поля будут заполнены предыдущими значениями.',
  cancelBtnText = 'Отменить',
  footerStyle,
  actionsSx,
  submitButtonProps,
  autoFocusNext = false,
  showSubmitButton = true,
  stopPropagation,
}: FormProps<T>) => {
  const [showResetFormConfirm, setShowResetFromConfirm] = useState(false);
  const formContainerRef = useRef<HTMLFormElement>(null);
  const { text: submitBtnText = 'Сохранить', ...submitBtnProps } =
    submitButtonProps ?? {};

  const isFooterSticky =
    btnFollowScroll &&
    (footer === undefined || (footer && !isValidElement(footer)));

  const onFormSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (stopPropagation) {
      e.stopPropagation();
    }

    if (onSubmit) {
      form.handleSubmit(onSubmit, onSubmitError)(e);
    }
  };

  const handleClickCancelButton = () => {
    if (showCancelConfirm && form.formState.isDirty) {
      setShowResetFromConfirm(true);
      return;
    }

    form.reset();
    onCancel?.();
  };

  useEffect(() => {
    const formRef = formContainerRef.current;
    const handleFocus = (e: any) => {
      const { key, target } = e;
      const { id, value, tagName } = target;
      if (tagName === 'TEXTAREA' && key === 'Enter' && e.ctrlKey) {
        const newValue: string = `${value}\n`;
        form.setValue(id, newValue as FieldPathValue<T, FieldPath<T>>);
      } else if (
        (target.tagName === 'INPUT' || tagName === 'TEXTAREA') &&
        key === 'Enter' &&
        formContainerRef.current
      ) {
        (
          Array.from(
            formContainerRef.current.getElementsByTagName('*')
          ) as TInputElement[]
        )
          .filter(
            (element) =>
              (element.tagName === 'INPUT' || element.tagName === 'TEXTAREA') &&
              element.id !== '' &&
              element.type !== 'checkbox'
          )
          .forEach((item, index, elems) => {
            if (
              item?.id === id &&
              index < elems.length - 1 &&
              elems[index + 1]
            ) {
              e.preventDefault();
              elems[index + 1].focus();
            }
          });
      }
    };

    if (autoFocusNext && formRef) {
      formRef.addEventListener('keydown', handleFocus);
    }

    return () => {
      if (autoFocusNext && formRef) {
        formRef.removeEventListener('keydown', handleFocus);
      }
    };
  }, [autoFocusNext, form]);

  return (
    <FormProvider {...form}>
      <form
        noValidate
        onSubmit={onFormSubmit}
        ref={formContainerRef}
        className={classNames(
          styles.form,
          loading || preloading ? styles.formLoading : undefined,
          className
        )}>
        {children}

        {isValidElement(footer) ? (
          <Box
            mt="12px"
            display="flex"
            flexDirection="column"
            className={btnFollowScroll ? styles.followScroll : undefined}>
            {footer}
          </Box>
        ) : (
          (footer || footer === undefined) &&
          !viewMode && (
            <Box
              mt="12px"
              display="flex"
              flexDirection="column"
              className={btnFollowScroll ? styles.followScroll : undefined}>
              <div
                style={footerStyle}
                className={
                  extraActions
                    ? styles.footerCoverExtraActions
                    : styles.footerCoverNoExtraActions
                }>
                {extraActions}
                <Box gap={3} display="flex" sx={actionsSx}>
                  {form.formState.isDirty || skipDirtyCheck ? (
                    <>
                      {showSubmitButton && (
                        <Button
                          loading={loading}
                          type="submit"
                          {...submitBtnProps}>
                          {submitBtnText}
                        </Button>
                      )}

                      {showCancelButton && (
                        <Box>
                          <Button
                            color="secondary"
                            onClick={handleClickCancelButton}>
                            {cancelBtnText}
                          </Button>

                          <ConfirmDialog
                            open={showResetFormConfirm}
                            close={() => setShowResetFromConfirm(false)}
                            title="Отменить изменения?"
                            confirmText="Да, отменить"
                            onConfirm={() => {
                              setShowResetFromConfirm(false);
                              form.reset();
                              onCancel?.();
                            }}>
                            {cancelConfirmText}
                          </ConfirmDialog>
                        </Box>
                      )}
                    </>
                  ) : (
                    onCancel && (
                      <Button color="secondary" onClick={onCancel}>
                        {cancelBtnText}
                      </Button>
                    )
                  )}
                </Box>
              </div>

              {isFooterSticky && form.formState.errors.root?.serverError && (
                <FormError
                  messageError={form.formState.errors.root.serverError.message}
                />
              )}
            </Box>
          )
        )}

        {!isFooterSticky && form.formState.errors.root?.serverError && (
          <FormError
            messageError={form.formState.errors.root.serverError.message}
          />
        )}
      </form>
    </FormProvider>
  );
};

Form.List = FormList;
Form.Item = FormItem;

export { Form };
