import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// Хук для отображения модалки при выходе со страницы заявки
// через меню или нашу кнопку назад
export const useConfirmLeavePage = (
  shouldTrigger: boolean
): [boolean, () => void] => {
  const navigate = useNavigate();

  const { state, pathname } = useLocation();
  const backUrl = useRef(state);

  const [leaveConfirmation, setLeaveConfirmation] = useState(false);

  const [navHref, setNavHref] = useState('');

  // Для обработки через кнопку назад
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (shouldTrigger) {
      const headerBackButton = document.getElementById('header-back-button');

      const backNavigationHandler = (event: any) => {
        event.stopPropagation();
        event.preventDefault();
        setLeaveConfirmation(true);
      };

      headerBackButton!.addEventListener('click', backNavigationHandler);

      return () =>
        headerBackButton!.removeEventListener('click', backNavigationHandler);
    }
  }, []);

  // Для обработки ухода со страницы через Navigation Menu
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (shouldTrigger) {
      const topNavigationMenu = document.querySelector(
        'div[class*="header_wrapper"]'
      );

      const allNavigationMenuLinks = topNavigationMenu!.querySelectorAll('a');

      // eslint-disable-next-line func-names
      const linkClickHandler = function (
        this: HTMLAnchorElement,
        event: MouseEvent
      ) {
        // eslint-disable-next-line react/no-this-in-sfc
        const ref = this.href;
        const actualRef = ref.split('/')[ref.split('/').length - 1];

        event.preventDefault();

        if (
          actualRef === 'admin' ||
          /u\d+/.test(actualRef) ||
          ref.split('/').length < 2
        ) {
          setNavHref('/');
        } else {
          setNavHref(`/${actualRef}`);
        }

        setLeaveConfirmation(true);
      };

      allNavigationMenuLinks.forEach((navLink) => {
        navLink.addEventListener('click', linkClickHandler);
      });

      return () => {
        allNavigationMenuLinks.forEach((navLink) => {
          navLink.removeEventListener('click', linkClickHandler);
        });
      };
    }
  }, []);

  const handleLeavePage = () => {
    setLeaveConfirmation(false);

    if (navHref) {
      navigate(navHref);
      return;
    }

    if (backUrl.current?.prevUrl) {
      navigate(backUrl.current.prevUrl, { state: { prevUrl: pathname } });
    } else {
      navigate(-1);
    }
  };

  return [leaveConfirmation, handleLeavePage];
};
