import { InputAdornment, Stack, Theme, useMediaQuery } from '@mui/material';
import classNames from 'classnames';
import { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Virtuoso } from 'react-virtuoso';
import { CloseIcon, Search } from 'src/assets/icons';
import {
  Box,
  Button,
  Checkbox,
  Form,
  IconButton,
  OutlinedInput,
} from 'src/components/ui/index';
import CheckboxFormItemChip from 'src/components/ui/new-filters/components/filters-popover/components/checkbox-form/components/checkbox-form-item-chip';
import { FiltersDrawer } from 'src/components/ui/new-filters/components/filters-popover/components/filters-drawer';
import commonStyles from 'src/components/ui/new-filters/filters.module.scss';
import {
  TFilterItemBaseProps,
  TFilterValue,
  TVariant,
} from 'src/components/ui/new-filters/types';

import styles from './checkbox-form.module.scss';

export type CheckboxFormProps<FilterKeys extends string = string> = {
  withSearch?: boolean;
  className?: string;
  placeholder?: string;
  removeSort?: boolean;
  variantsList: TVariant[];
  filterKey: string;
  itemHeight?: number;
  showSelectAllButton?: boolean;
  // Нужно для переиспользования формы на втором drawer'е для мобилок
  showDesktopVariant?: boolean;
  // Нужно для использования в виде фильтра с одним чекбоксом
  toggleMode?: boolean;
} & TFilterItemBaseProps<FilterKeys>;

export const CheckboxForm = <FilterKeys extends string = string>({
  filter,
  updateFilterValue,
  filterKey,
  removeSort = false,
  variantsList,
  withSearch,
  className,
  placeholder,
  itemHeight,
  showSelectAllButton,
  showAllVariantsList,
  closeAllVariantsList,
  allVariantsTitle,
  showDesktopVariant,
  toggleMode,
}: CheckboxFormProps<FilterKeys>) => {
  const isDesktop =
    showDesktopVariant ||
    useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));

  const variantsContainerRef = useRef<HTMLDivElement | null>(null);
  // Нужно для динамического расчета высоты virtuoso, когда форма используется в
  // drawer'е со всеми опциями, который отображается на мобильных устройствах
  const [virtuosoHeight, setVirtuosoHeight] = useState<number | undefined>(
    undefined
  );
  useEffect(() => {
    if (variantsContainerRef.current && showDesktopVariant) {
      setVirtuosoHeight(variantsContainerRef.current.clientHeight);
    }
  }, [variantsContainerRef.current]);

  const [currentVariantsList, setCurrentVariantsList] = useState(
    variantsList.filter((variant) => variant.label)
  );

  const [canClear, setCanClear] = useState(false);
  const [isInitiallySorted, setIsInitiallySorted] = useState(false);
  const [searchInputValue, setSearchInputValue] = useState('');
  const [searchQuery, setSearchQuery] = useState('');

  const formVariants = useForm({
    defaultValues: {
      [filterKey]: variantsList.filter((variant) => variant.label),
    },
  });

  useEffect(() => {
    // Todo: разобраться почему в toggleMode неправильно отображается значение чекбокса
    if (!toggleMode) {
      setCurrentVariantsList(variantsList.filter((variant) => variant.label));
    }
  }, [variantsList]);

  useEffect(() => {
    if (variantsList.length === 0) {
      return;
    }
    if (!filter) {
      const updatedVariantsList = currentVariantsList.map((variantItem) => ({
        ...variantItem,
        checked: toggleMode ? variantItem.checked : false,
      }));
      setIsInitiallySorted(true);
      setCurrentVariantsList(updatedVariantsList);
    } else {
      let updatedVariantsList = currentVariantsList.map((variantItem) => ({
        ...variantItem,
        checked: toggleMode
          ? (filter.value as string[])[0] === 'true'
          : Boolean(
              (filter.value as string[]).find(
                (filterValueItem) => filterValueItem === variantItem.value
              )
            ),
      }));
      if (!removeSort && !isInitiallySorted) {
        updatedVariantsList = updatedVariantsList.sort(
          (a, b) => +b.checked - +a.checked
        );
        setIsInitiallySorted(true);
      }
      setCurrentVariantsList(updatedVariantsList);
    }
  }, [filter]);

  const filterList = () => {
    setSearchQuery(searchInputValue);
  };

  const clearSearch = () => {
    setSearchInputValue('');
    setSearchQuery('');
    setCanClear(false);
  };

  useEffect(() => {
    if (searchInputValue) {
      setCanClear(true);
      return;
    }
    setCanClear(false);
  }, [searchInputValue]);

  const filteredVariantsList = useMemo(
    () =>
      currentVariantsList.filter((variant) =>
        variant.label.toLowerCase().includes(searchQuery.toLowerCase())
      ),
    [currentVariantsList, searchQuery]
  );

  useEffect(() => {
    if (!isDesktop) {
      setSearchQuery('');
    }
  }, [isDesktop]);

  const toggleSelectedVariant = (index: number) => {
    const selectedVariant = filteredVariantsList[index];
    const isValueAlreadyPresent = ((filter?.value as string[]) ?? []).find(
      (value) => value === selectedVariant.value
    );
    const currentFilterValue = (filter?.value as string[]) ?? [];
    const currentFilterStringRepresentation =
      (filter?.stringRepresentation as string[]) ?? [];
    if (isValueAlreadyPresent) {
      const updatedFilter: TFilterValue<FilterKeys> = {
        key: filterKey,
        value: currentFilterValue.filter(
          (item) => item !== selectedVariant.value
        ),
        stringRepresentation: currentFilterStringRepresentation.filter(
          (item) => item !== selectedVariant.label
        ),
      };
      updateFilterValue(updatedFilter);
    } else {
      const updatedFilter: TFilterValue<FilterKeys> = {
        key: filterKey,
        value: [...currentFilterValue, selectedVariant.value],
        stringRepresentation: [
          ...currentFilterStringRepresentation,
          selectedVariant.label,
        ],
      };
      updateFilterValue(updatedFilter);
    }
  };

  const handleToggleSelectedVariantInToggleMode = () => {
    const selectedVariant = variantsList[0];
    const isChecked =
      (!filter && selectedVariant.checked) ||
      (filter?.value as string[])[0] === 'true';

    const updatedFilter: TFilterValue<FilterKeys> = {
      key: filterKey,
      value: isChecked ? ['false'] : ['true'],
      stringRepresentation: !isChecked ? selectedVariant.label : '',
    };
    updateFilterValue(updatedFilter);
  };

  const handleSelectAll = () => {
    const allSelectedVariants = filteredVariantsList.map((variant) => ({
      ...variant,
      checked: true,
    }));

    const updatedFilter: TFilterValue<FilterKeys> = {
      key: filterKey,
      value: [...allSelectedVariants.map((variant) => variant.value)],
      stringRepresentation: [
        ...allSelectedVariants.map((variant) => variant.label),
      ],
    };

    updateFilterValue(updatedFilter);
  };

  const itemComponent = (index: number, item: TVariant) => (
    <Box mb={1} key={item.value}>
      <Form.Item
        noMargin
        name={`${filterKey}.${index}.checked`}
        label={item.label}
        isControlLabel>
        <Checkbox
          checked={filteredVariantsList[index].checked}
          onChange={() =>
            toggleMode
              ? handleToggleSelectedVariantInToggleMode()
              : toggleSelectedVariant(index)
          }
        />
      </Form.Item>
    </Box>
  );

  return (
    <Stack
      direction="column"
      gap="12px"
      height={showDesktopVariant ? '100%' : 'none'}>
      {withSearch && isDesktop && (
        <OutlinedInput
          value={searchInputValue}
          applyNewIconSizing
          fullWidth
          placeholder={placeholder ?? 'Поиск'}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setSearchInputValue(e.target.value);
          }}
          startAdornment={
            <InputAdornment position="start">
              <IconButton onClick={() => filterList()} aria-label="search">
                <Search />
              </IconButton>
            </InputAdornment>
          }
          endAdornment={
            <IconButton
              onClick={() => clearSearch()}
              className={classNames(
                commonStyles.clearBtn,
                !canClear && commonStyles.hidden
              )}>
              <CloseIcon />
            </IconButton>
          }
          onKeyPress={(e) => {
            if (e.charCode === 13) {
              filterList();
            }
          }}
        />
      )}
      {showSelectAllButton && isDesktop && (
        <Button variant="text" size="small" onClick={handleSelectAll}>
          Выбрать все
        </Button>
      )}
      <div
        ref={variantsContainerRef}
        className={classNames(
          styles.variantContainer,
          (!isDesktop || showDesktopVariant) &&
            styles.fullHeightVariantContainer
        )}>
        <Form form={formVariants} footer={false}>
          {isDesktop &&
            variantsList.length >= 40 &&
            filteredVariantsList.length > 0 && (
              <div className={className && styles[className]}>
                <Virtuoso
                  style={{
                    height: virtuosoHeight ? `${virtuosoHeight}px` : '150px',
                  }}
                  data={filteredVariantsList}
                  itemContent={itemComponent}
                  fixedItemHeight={itemHeight}
                  className={classNames(
                    styles.variantContainer,
                    showDesktopVariant && styles.fullHeightVariantContainer
                  )}
                />
              </div>
            )}
          {isDesktop &&
            variantsList.length < 40 &&
            filteredVariantsList.length > 0 && (
              <div className={className && styles[className]}>
                {filteredVariantsList.map((variant, index) =>
                  itemComponent(index, variant)
                )}
              </div>
            )}
          {!isDesktop && (
            <Stack direction="row" gap="8px" flexWrap="wrap">
              {filteredVariantsList.slice(0, 10).map((variant, index) => (
                <CheckboxFormItemChip
                  checked={filteredVariantsList[index].checked}
                  onChange={() =>
                    toggleMode
                      ? handleToggleSelectedVariantInToggleMode()
                      : toggleSelectedVariant(index)
                  }
                  label={variant.label}
                />
              ))}
            </Stack>
          )}
        </Form>
      </div>
      <FiltersDrawer
        title={allVariantsTitle}
        open={showAllVariantsList}
        close={closeAllVariantsList}
        buttons={
          <Button sx={{ flex: 1 }} onClick={closeAllVariantsList}>
            Готово
          </Button>
        }>
        <CheckboxForm
          variantsList={variantsList}
          filterKey={filterKey}
          updateFilterValue={updateFilterValue}
          showSelectAllButton={showSelectAllButton}
          withSearch={withSearch}
          filter={filter}
          showDesktopVariant
        />
      </FiltersDrawer>
    </Stack>
  );
};
