import { SxProps, Theme } from '@mui/material';
import classNames from 'classnames';
import { MouseEvent, useRef, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { CloseIcon, Search } from 'src/assets/icons';
import {
  Autocomplete,
  Form,
  IconButton,
  InputAdornment,
} from 'src/components/ui';
import { FiltersAutocompleteProps } from 'src/components/ui/new-filters/types';
import commonStyles from '../../filters.module.scss';
import styles from './search-form.module.scss';

type TFormInput = {
  searchQuery: string;
};

type TProps<AutocompleteItemType> = {
  onSearch: (searchQuery: string) => void;
  handleClearFilters: () => void;
  onClick?: (event: MouseEvent<HTMLElement>) => void;
  isDisabled?: boolean;
  defaultInput?: boolean;
  countChips?: number;
  inputSx?: SxProps<Theme>;
  autocompleteOptions?: FiltersAutocompleteProps<AutocompleteItemType>;
  whiteVariant?: boolean;
};

export const SearchForm = <AutocompleteItemType,>({
  onSearch,
  handleClearFilters,
  onClick,
  isDisabled,
  defaultInput,
  countChips,
  inputSx,
  autocompleteOptions,
  whiteVariant,
}: TProps<AutocompleteItemType>) => {
  const clearBtn = useRef<any>(null);

  const form = useForm<TFormInput>({
    defaultValues: {
      searchQuery: '',
    },
  });

  const query = form.watch('searchQuery');
  const canClear = (countChips && countChips > 0) || !!query;

  // Без изменения key невозможно программно изменить значение input в autocomplete
  const [autocompleteKey, setAutocompleteKey] = useState('');

  const [currentInputValue, setCurrentInputValue] = useState('');

  const hintItemsRef = useRef<AutocompleteItemType[]>([]);

  const handleClearForm = () => {
    form.reset();

    // TODO: Проверить, не сломалось ли что-нибудь? Если нет, то удалить проверку
    // if (countChips) {
    //   handleClearFilters();
    // }

    handleClearFilters();
  };

  const handleClickSearchForm = (e: MouseEvent<HTMLElement>) => {
    if (clearBtn.current && !clearBtn.current.contains(e.target)) {
      onClick?.(e);
    }
  };

  const onSubmit: SubmitHandler<TFormInput> = ({ searchQuery }) => {
    // Если только одна подсказка, то при нажатии Enter она попадает в поиск полностью
    if (
      autocompleteOptions &&
      hintItemsRef.current.length &&
      hintItemsRef.current.length === 1
    ) {
      onSearch(autocompleteOptions.getOptionLabel(hintItemsRef.current[0]));
      setAutocompleteKey(
        autocompleteOptions.getOptionLabel(hintItemsRef.current[0])
      );
    } else {
      onSearch(searchQuery || currentInputValue);
      setAutocompleteKey(searchQuery || currentInputValue);
    }

    if (defaultInput) {
      return;
    }

    setCurrentInputValue('');
    form.reset();
  };

  return (
    <Form form={form} onSubmit={onSubmit} viewMode>
      <Form.Item name="searchQuery" noMargin>
        <Autocomplete
          placeholder="Поиск"
          disableClearable={true as any}
          freeSolo
          open={!autocompleteOptions ? false : undefined}
          key={autocompleteKey}
          fetchOnEmptyInput={false}
          options={[]}
          fetchApi={
            autocompleteOptions?.fetchApi ?? (() => Promise.resolve([]))
          }
          onSetOptions={(value) => {
            hintItemsRef.current = value;
          }}
          onInputChange={(_, value) => setCurrentInputValue(value)}
          renderOption={autocompleteOptions?.renderOption}
          getOptionLabel={autocompleteOptions?.getOptionLabel}
          onChange={(value) => {
            if (autocompleteOptions?.getOptionLabel) {
              onSubmit({
                searchQuery: autocompleteOptions.getOptionLabel(
                  value as AutocompleteItemType
                ),
              });
            }
          }}
          inputProps={{
            disabled: !!isDisabled,
            onClick: handleClickSearchForm,
            className: classNames(
              isDisabled && styles.disable,
              whiteVariant && styles.whiteInput,
              !whiteVariant && styles.grayInput
            ),
            trimValue: false,
            type: 'text',
            fullWidth: true,
            applyNewIconSizing: true,
            startAdornment: (
              <InputAdornment position="start">
                <IconButton type="submit" aria-label="search">
                  <Search />
                </IconButton>
              </InputAdornment>
            ),
            endAdornment: (
              <IconButton
                ref={clearBtn}
                onClick={handleClearForm}
                className={classNames(
                  commonStyles.clearBtn,
                  !canClear && commonStyles.hidden
                )}>
                <CloseIcon />
              </IconButton>
            ),
            sx: inputSx,
          }}
        />
      </Form.Item>
    </Form>
  );
};
