import classNames from 'classnames';
import {
  CSSProperties,
  HTMLAttributes,
  ReactNode,
  useEffect,
  useState,
} from 'react';
import { RoundedChevronRight } from 'src/assets/icons';

import { Box, Typography } from 'src/components/ui';
import styles from './new-card.module.scss';

interface INewCardProps extends Omit<HTMLAttributes<HTMLDivElement>, 'title'> {
  title?: ReactNode;
  actions?: ReactNode;
  noContentPadding?: boolean;
  collapsible?: boolean;
  isCollapsed?: boolean;
  headerStyle?: CSSProperties;
  wrapperStyle?: CSSProperties;
  cardRef?: React.Ref<any>;
  onCollapseChange?: (val: boolean) => void;
  onHeaderClick?: () => void;
}

export const NewCard = ({
  title,
  children,
  actions,
  noContentPadding,
  className,
  collapsible = false,
  isCollapsed = false,
  headerStyle,
  cardRef,
  onCollapseChange,
  wrapperStyle,
  onHeaderClick,
  ...rest
}: INewCardProps) => {
  const [collapsed, setCollapsed] = useState(collapsible && isCollapsed);

  const handleChangeCollapse = () => {
    setCollapsed((prev) => {
      onCollapseChange?.(!prev);
      return !prev;
    });
  };

  useEffect(() => {
    setCollapsed(collapsible && isCollapsed);
  }, [collapsible, isCollapsed]);

  return (
    <div
      {...rest}
      ref={cardRef}
      className={classNames(styles.wrapper, className)}
      style={wrapperStyle}>
      {title && (
        <div
          className={classNames(
            styles.header,
            collapsed && styles.headerNoBottomBorder
          )}
          style={{
            ...headerStyle,
            cursor: onHeaderClick ? 'pointer' : undefined,
          }}
          onClick={handleChangeCollapse}>
          {collapsible && (
            <Box
              sx={(theme) => ({
                display: 'flex',
                color: theme.palette.primary.main,
                transform: collapsed ? undefined : 'rotate(270deg)',
              })}>
              <RoundedChevronRight />
            </Box>
          )}
          <Typography variant="h2" flex="1 1 auto">
            {title}
          </Typography>
          {actions && (
            <div
              onClick={(event) => {
                event.stopPropagation();
              }}>
              {actions}
            </div>
          )}
        </div>
      )}
      {!collapsed && children && (
        <div
          className={classNames(
            styles.content,
            noContentPadding && styles.contentNoPadding
          )}>
          {children}
        </div>
      )}
    </div>
  );
};
