import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { businessRequestApi, documentApi } from 'src/api';
import { TContractorListFilter } from 'src/api/contractor';
import { TSortKey } from 'src/components/pages/contractors/config';
import { DIRECTION, TSort } from 'src/components/ui/filters/types';
import { TContractorList } from 'src/models';
import {
  TGetContractorRequestsInfoResponse,
  TGetContractorsKanbanResponse,
} from 'src/models/contractor';
import { DEFAULT_TAG_DISPLAY_NAME } from 'src/constants';
import { sortContractors } from './utils';

type TContractorRequestsInfo = {
  [key: number]: TGetContractorRequestsInfoResponse[number];
};

type TOrganizationCard = {
  [key: number]: {
    documentId: number;
    entityId: number;
    documentName: string;
  }[];
};

interface IInitialState {
  contractors: TContractorList;
  filters: TContractorListFilter;
  sort: TSort<TSortKey>;
  isInitialState: boolean;
  isSortSelected: boolean;
  filterTagsPositions: Record<string, number>;
}

export const defaultSort: TSort<TSortKey> = {
  field: 'createdAt',
  direction: DIRECTION.DESC,
};

const initialState: IInitialState = {
  contractors: {
    countByStatus: null,
    totalCount: null,
    items: [],
  },
  filters: {
    filter: {
      organizationStatusCodes: [],
    },
    searchQueries: [],
  },
  sort: defaultSort,
  isInitialState: true,
  isSortSelected: false,
  filterTagsPositions: {},
};

const contractorsSlice = createSlice({
  name: 'contractors',
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(
      businessRequestApi.endpoints.getContractorRequestsInfo.matchFulfilled,
      (state, { payload }) => {
        const contractorRequestsInfo = payload.reduce(
          (acc: TContractorRequestsInfo, elem) => {
            acc[elem.organizationId] = elem;
            return acc;
          },
          {}
        );
        const mergedData = state.contractors.items.map((category) => ({
          ...category,
          organizations: category.organizations.map((org) => ({
            ...org,
            contractorRequestsInfo: contractorRequestsInfo[org.id],
          })),
        }));
        state.contractors.items =
          state.sort.field === 'totalCount'
            ? sortContractors(mergedData, state.sort)
            : mergedData;
      }
    );
    builder.addMatcher(
      documentApi.endpoints.getOrganizationCardInfoByIds.matchFulfilled,
      (state, { payload }) => {
        const organizationCardInfo = payload.reduce(
          (acc: TOrganizationCard, elem) => {
            if (!acc[elem.entityId]) {
              acc[elem.entityId] = [elem];
            } else {
              acc[elem.entityId].push(elem);
            }
            return acc;
          },
          {}
        );
        const mergedData = state.contractors.items.map((category) => ({
          ...category,
          organizations: category.organizations.map((org) => ({
            ...org,
            documents: organizationCardInfo[org.id],
          })),
        }));
        state.contractors.items = mergedData;
      }
    );
  },
  reducers: {
    setCurrentFilters(
      state,
      { payload }: PayloadAction<TContractorListFilter>
    ) {
      state.filters = payload;
    },
    setPrepareContractorsData(
      state,
      {
        payload: { mappedTags, contractorsKanban },
      }: PayloadAction<{
        contractorsKanban: TGetContractorsKanbanResponse;
        mappedTags: {
          [key: number]: string;
        };
      }>
    ) {
      const { countByStatus, items, organizations, totalCount } =
        contractorsKanban;
      const mergedData = items.map((kanbanItem) => ({
        categoryId: kanbanItem.categoryId,
        organizations: organizations.filter((org) =>
          kanbanItem.organizationIds.includes(org.id)
        ),
      }));
      state.isInitialState = false;
      state.contractors = {
        totalCount,
        countByStatus,
        items: sortContractors(
          mergedData.sort((a, b) => {
            if (
              mappedTags[a.categoryId] === DEFAULT_TAG_DISPLAY_NAME &&
              mappedTags[b.categoryId] !== DEFAULT_TAG_DISPLAY_NAME
            ) {
              return 1;
            }
            if (
              mappedTags[a.categoryId] !== DEFAULT_TAG_DISPLAY_NAME &&
              mappedTags[b.categoryId] === DEFAULT_TAG_DISPLAY_NAME
            ) {
              return -1;
            }
            return 0;
          }),
          state.sort
        ),
      };
    },
    setSortedContractors(
      state,
      { payload }: PayloadAction<TSort<TSortKey> | null>
    ) {
      state.isSortSelected = !!payload;
      state.sort = payload ?? defaultSort;
      state.contractors.items = sortContractors(
        state.contractors.items,
        state.sort
      );
    },
    setFilterTagsPositions(
      state,
      { payload }: PayloadAction<Record<string, number>>
    ) {
      state.filterTagsPositions = payload;
    },
  },
});

export const {
  setCurrentFilters,
  setSortedContractors,
  setPrepareContractorsData,
  setFilterTagsPositions,
} = contractorsSlice.actions;

export const contractorsReducer = contractorsSlice.reducer;
