import {
  InvokeExpertStage,
  ISpecificRole,
  LogisticsCalculationDto,
  LogisticsCalculationRequest,
} from 'src/models/bids-details';
import { ContractStatuses } from 'src/models/contract';
import {
  BestKpStatusIcon,
  RefusedKpStatusIcon,
  SentKpStatusIcon,
} from 'src/assets/icons';
import { TCargoParametersFull, ICargoDescriptions } from '../cargo-parameters';
import { DocumentTypes, TDocument } from '../document';
import {
  CustomerCommercialOfferRejectReason,
  CustomerCommercialOfferReworkReason,
  CustomerCommercialOfferStatus,
} from './customer-commercial-offer';

export enum OrganizationStatus {
  ACTIVE = 'ACTIVE',
  LOCKED = 'LOCKED',
  SHOWS_NO_ACTIVITY = 'SHOWS_NO_ACTIVITY',
  LIMITED = 'LIMITED',
}

export enum ManagerContractorCommercialOfferStatus {
  WINNER = 'WINNER',
  REFUSED = 'REFUSED',
  RECEIVED = 'RECEIVED',
  BEST = 'BEST',
}

export enum ManagerContractorCommercialOfferStatusByCost {
  BEST_COST = 'BEST_COST',
  RESERVED_OFFER = 'RESERVED_OFFER',
}

export const ManagerContractorCommercialOfferStatusName: Record<
  ManagerContractorCommercialOfferStatus,
  string
> = {
  [ManagerContractorCommercialOfferStatus.WINNER]: 'Победитель',
  [ManagerContractorCommercialOfferStatus.REFUSED]: 'Отклонено',
  [ManagerContractorCommercialOfferStatus.RECEIVED]: 'КП получено',
  [ManagerContractorCommercialOfferStatus.BEST]: 'Лучшее КП',
};

export const ManagerContractorCommercialOfferStatusIcon: Record<
  ManagerContractorCommercialOfferStatus,
  React.JSX.Element
> = {
  [ManagerContractorCommercialOfferStatus.WINNER]: <BestKpStatusIcon />,
  [ManagerContractorCommercialOfferStatus.REFUSED]: <RefusedKpStatusIcon />,
  [ManagerContractorCommercialOfferStatus.RECEIVED]: <SentKpStatusIcon />,
  [ManagerContractorCommercialOfferStatus.BEST]: <BestKpStatusIcon />,
};

export enum ManagerCustomerCommercialOfferStatus {
  APPROVED = 'APPROVED',
  REFUSED = 'REFUSED',
  DRAFT = 'DRAFT',
  SENT = 'SENT',
  REFINED = 'REFINED',
}

export const ManagerCustomerCommercialOfferStatusName: Record<
  ManagerCustomerCommercialOfferStatus,
  string
> = {
  [ManagerCustomerCommercialOfferStatus.APPROVED]: 'Согласовано',
  [ManagerCustomerCommercialOfferStatus.REFUSED]: 'Отклонено',
  [ManagerCustomerCommercialOfferStatus.DRAFT]: 'Черновик',
  [ManagerCustomerCommercialOfferStatus.SENT]: 'КП отправлено',
  [ManagerCustomerCommercialOfferStatus.REFINED]: 'Есть уточнения',
};

export interface IManagerContractorCommercialOffer {
  contractorRequestId: number;
  commercialOfferId: number;
  number: number | null;

  contractorDetails: {
    organizationId: number;
    organizationName: string;
    organizationStatus: {
      id?: number;
      statusName?: string;
      description?: string;
      code: OrganizationStatus;
    };

    contractId: number;
    contractStatusCode: ContractStatuses;
    shippingAddress: string;
  };

  auction: {
    exclusion: {
      createdAt: string;
      cause: string;
    } | null;

    refusal: {
      createdAt: string;
      cause: string;
    } | null;
  };

  contractorRequestData: {
    exclusion: {
      createdAt: string;
      cause: string;
    } | null;

    refusal: {
      cause: string;
      comment: string;
      createdAt: string;
    } | null;
  };

  refusal: {
    cause: string;
    comment: string;
    createdAt: string;
  } | null;

  supplySum: number;
  status: ManagerContractorCommercialOfferStatus;
  statusByCost: ManagerContractorCommercialOfferStatusByCost | null;
  receivedAt: string;
  costByContractor: number;
  dueDateAt: string;
  supplyDuration: number;
  paymentConditions: string;
  comment: string;
  auctionCommercialOffer: boolean;
  rebiddingCommercialOffer: boolean;

  shippingIncluded: boolean;

  shipping: {
    deliveryAddress: string;
    shippingAddress: string;

    calculatedShippingCost: number | null;
    manualShippingCost: number | null;

    cargoDescription: TCargoParametersFull | null;
  };

  documents: TDocument[];
}

export interface IManagerContractorCommercialOfferParams {
  commercialOfferId: number;

  shippingIncluded: boolean;
  shipping: {
    deliveryAddress: string;
    shippingAddress: string;
    manualShippingCost: number | null;

    cargoDescription: ICargoDescriptions['parameters'] | null;
  };

  costByContractor: number;
  dueDateAt: string | null;
  supplyDuration: number;
  paymentConditions: string;
  comment: string;
}

export interface ICommercialOfferRefusalCauses {
  totalCount: number;
  page: number;

  items: {
    id: number;
    name: string;
    status: string;
  }[];
}

export interface LogisticsCalculationRequestResponse
  extends LogisticsCalculationRequest {
  organizationName?: string;
}

export interface IManagerCustomerCommercialOffer {
  businessApplicationId: number;
  number: number | null;
  commercialOfferId: number;
  costByContractor: number | null;
  costForCustomer: number | null;
  shippingCost: number | null;
  shippingIncluded: boolean;
  income: number | null;
  dueDateAt: string | null;
  supplyDuration: number;
  processingDueDateAt: string | null;
  paymentConditions: string;
  comment: string;
  createdAt: string | null;
  statusForCustomer?: {
    code: CustomerCommercialOfferStatus;
  };
  status: {
    code: ManagerCustomerCommercialOfferStatus;
    createdAt: string | null;
    displayName: string;
  };

  assignedSpecificRoles: Record<InvokeExpertStage, ISpecificRole[] | undefined>;

  logisticsCalculationRequest?: LogisticsCalculationRequestResponse;
  logisticsCalculationDto?: LogisticsCalculationDto;

  draftLastModifiedAt: string;
  sentAt: string | null;

  contractorCommercialOffer: {
    id: number;
    number: number;
  } | null;

  contractorData: {
    organizationName: string;
    organizationId: number;
    contractId: number;
    contractStatus: ContractStatuses;
  } | null;

  refusal: {
    cause: string;
    comment: string;
    createdAt: string;
  } | null;

  refine: null | {
    refineCause: CustomerCommercialOfferReworkReason;
    rejectCause: CustomerCommercialOfferRejectReason;
    refineCauseDisplayName: string;
    rejectCauseDisplayName: string;
    comment: string;
    delayedResponseDate?: string;
  };

  documents: {
    [DocumentTypes.COMMERCIAL_OFFER]?: TDocument[];
  };
}

export interface ISetRefinedCommercialOfferProps {
  commercialOfferId: number;
  businessRequestId: number;
  businessApplicationId: number;
  refineCause: string;
  comment?: string;
  delayedResponseDate?: Date | null;
}
