import {
  CSSProperties,
  FC,
  HTMLAttributes,
  ReactNode,
  useEffect,
  useState,
} from 'react';
import classNames from 'classnames';

import { IconButton, Typography, Box } from 'src/components/ui';
import { ArrowDown } from 'src/assets/icons';
import styles from './card.module.scss';

type TExpandMoreProps = {
  handleChangeCollapse: () => void;
  collapsed: boolean;
};

interface ICardProps extends Omit<HTMLAttributes<HTMLDivElement>, 'title'> {
  title?: ReactNode;
  titleFooter?: ReactNode;
  actions?: ReactNode;
  footer?: ReactNode;
  noMargin?: boolean;
  noShadow?: boolean;
  noContentPadding?: boolean;
  collapsible?: boolean;
  isCollapsed?: boolean;
  headerStyle?: CSSProperties;
  wrapperStyle?: CSSProperties;
  cardRef?: React.Ref<any>;
  onCollapseChange?: (val: boolean) => void;
  onHeaderClick?: () => void;
}

const ExpandMore: FC<TExpandMoreProps> = ({
  handleChangeCollapse,
  collapsed,
}) => (
  <IconButton
    onClick={handleChangeCollapse}
    className={!collapsed ? styles.expandMoreButtonRotated : undefined}>
    <ArrowDown />
  </IconButton>
);

export const Card: FC<ICardProps> = ({
  title,
  titleFooter,
  children,
  actions,
  footer,
  noMargin,
  noContentPadding,
  className,
  collapsible = false,
  isCollapsed = false,
  headerStyle,
  cardRef,
  onCollapseChange,
  noShadow,
  wrapperStyle,
  onHeaderClick,
  ...rest
}) => {
  const [collapsed, setCollapsed] = useState(collapsible && isCollapsed);

  const handleChangeCollapse = () => {
    setCollapsed((prev) => {
      onCollapseChange?.(!prev);
      return !prev;
    });
  };

  useEffect(() => {
    setCollapsed(collapsible && isCollapsed);
  }, [collapsible, isCollapsed]);

  return (
    <div
      {...rest}
      ref={cardRef}
      className={classNames(
        styles.wrapper,
        className,
        noMargin && styles.wrapperNoMargin,
        noShadow && styles.wrapperNoShadow
      )}
      style={wrapperStyle}>
      {title && (
        <div
          className={styles.header}
          style={{
            ...headerStyle,
            cursor: onHeaderClick ? 'pointer' : undefined,
          }}
          onClick={onHeaderClick}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
            }}>
            <Typography variant="h2" flex="1 1 auto">
              {title}
            </Typography>
            {actions}
            {collapsible && (
              <ExpandMore
                collapsed={collapsed}
                handleChangeCollapse={handleChangeCollapse}
              />
            )}
          </div>
          {titleFooter && <div style={{ width: '100%' }}>{titleFooter}</div>}
        </div>
      )}
      {!collapsed && children && (
        <div
          className={classNames(
            styles.content,
            noContentPadding && styles.contentNoPadding
          )}>
          {children}

          {footer && (
            <Box display="flex" justifyContent="flex-end">
              {footer}
            </Box>
          )}
        </div>
      )}
    </div>
  );
};
