import { FC } from 'react';
import { CloseIcon } from 'src/assets/icons';
import { Button, IconButton, SvgIcon, Typography } from 'src/components/ui';
import vars from 'src/theme/scss/_vars.scss';
import { ToastContentProps } from 'react-toastify';
import {
  setCurrentNotification,
  setReadNotification,
} from 'src/store/notifications';
import { useAppDispatch } from 'src/store';
import { TNotification } from 'src/models/notifications';
import { notificationsSocket } from 'src/utils';
import { AuthService } from 'src/services';
import { useNavigate } from 'react-router-dom';
import styles from './notification-card.module.scss';
import { getNotificationDate } from '../../utils/get-notification-date';
import { getIconByTypeNotification } from '../../utils/get-icon-by-type-notification';

type TNotificationCardProps = Partial<
  Omit<ToastContentProps, 'data'> & {
    data: TNotification;
  }
>;

export const NotificationCard: FC<TNotificationCardProps> = ({
  closeToast,
  data,
}) => {
  if (!data) return <div />;

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { userId, isGuestAuth } = AuthService;

  const handleOnClick = (notification: TNotification | undefined) => {
    if (notification) {
      dispatch(setCurrentNotification(notification));
      if (!isGuestAuth) {
        dispatch(setReadNotification(notification.id));
        notificationsSocket.emit('NOTIFICATION_WATCHED', {
          userId,
          notificationId: notification.id,
        });
      }
      closeToast?.();
      navigate('/notifications');
    }
  };

  const { titleTemplate, notificationType, title, createdAt } = data;

  return (
    <div className={styles.wrapper}>
      <div className={styles.mainPart}>
        {getIconByTypeNotification(notificationType)}
        <Typography className={styles.textValue}>
          {title ?? titleTemplate}
        </Typography>
        <IconButton
          className={styles.iconWrapper}
          onClick={() => closeToast?.()}>
          <SvgIcon
            icon={CloseIcon}
            strokeHexColor={vars.secondaryLight}
            width="10"
            height="10"
          />
        </IconButton>
      </div>
      <div className={styles.footerPart}>
        <Typography variant="body2" color="secondary">
          {getNotificationDate(createdAt, false)}
        </Typography>
        <Button
          variant="text"
          className={styles.moreButton}
          onClick={() => handleOnClick(data)}>
          Подробнее
        </Button>
      </div>
    </div>
  );
};
