import { Skeleton, Stack, Typography } from '@mui/material';
import { AddTableItemIcon } from 'src/assets/icons';
import { Button } from 'src/components/ui';
import { NewFilters } from 'src/components/ui/new-filters';
import { FiltersProps } from 'src/components/ui/new-filters/types';

interface PageHeaderProps<
  KeysSorting,
  AutocompleteItemType = {},
  FilterKeys extends string = string
> extends FiltersProps<KeysSorting, AutocompleteItemType, FilterKeys> {
  title: string;
  totalCountLabel: string;
  totalCount: number;
  isFiltersLoading?: boolean;
  addButtonLabel?: string;
  addButtonLoading?: boolean;
  onAddButtonClick?: () => void;
}

export const PageHeader = <
  KeysSorting,
  AutocompleteItemType = {},
  FilterKeys extends string = string
>({
  title,
  totalCountLabel,
  totalCount,
  isFiltersLoading,
  addButtonLabel,
  addButtonLoading,
  onAddButtonClick,
  ...filterProps
}: PageHeaderProps<KeysSorting, AutocompleteItemType, FilterKeys>) => (
  <Stack direction="row" alignItems="center" flex={1}>
    <Stack direction="row" alignItems="center" gap="24px" flex={1}>
      <Stack direction="column" gap="4px">
        <Typography fontWeight={500} fontSize="20px" lineHeight="24px">
          {title}
        </Typography>
        <Typography
          fontWeight={500}
          fontSize="12px"
          lineHeight="12px"
          color="text.secondary">
          {totalCountLabel}: {totalCount}
        </Typography>
      </Stack>
      <Stack direction="row" gap="8px" alignItems="center" flex={1}>
        {isFiltersLoading ? (
          <Skeleton height="45px" width="100%" />
        ) : (
          <NewFilters {...filterProps} />
        )}
        {addButtonLabel && onAddButtonClick && (
          <Button
            applyNewIconSizing
            startIcon={<AddTableItemIcon />}
            loading={addButtonLoading}
            onClick={onAddButtonClick}>
            {addButtonLabel}
          </Button>
        )}
      </Stack>
    </Stack>
  </Stack>
);
