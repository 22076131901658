import { FC, useState } from 'react';
import {
  SvgIcon,
  Typography,
  Card,
  Button,
  FormControlLabel,
  Switch,
} from 'src/components/ui';
import { Plus } from 'src/assets/icons';
import { Stack } from '@mui/material';
import { AddRoleModal, CompanyComment } from 'src/components/widgets';
import { getErrorMessage } from 'src/store/utils';
import { CompanyStatuses, CompanyTypes, ICompany } from 'src/models';
import {
  useChangeStatusMutation,
  useUpdateCompanyMutation,
} from 'src/api/company';
import { isLocked } from 'src/utils/company';
import { RolePermissionService } from 'src/services';
import { PERMISSIONS } from 'src/constants';
import styles from './company-settings-card.module.scss';

interface ICompanySettingsCardProps {
  companyData: ICompany;
  permissions?: Record<string, any>;
  isAdmin?: boolean;
}

export const CompanySettingsCard: FC<ICompanySettingsCardProps> = ({
  companyData,
  permissions,
  isAdmin,
}) => {
  const [status, setStatus] = useState('');
  const [isLockedStatus, setIsLocked] = useState<boolean>(
    isLocked(companyData.organizationStatus.code)
  );
  const [newRole, setNewRole] = useState<CompanyTypes | null>(null);

  const [
    changeStatus,
    { isLoading: isChangeStatusLoading, error: isChangeStatusError },
  ] = useChangeStatusMutation();

  const canAddContractor =
    RolePermissionService.can(PERMISSIONS.VIEW_CONTRACTOR_ROLE) &&
    !companyData.roles.includes(CompanyTypes.CONTRACTOR);

  const canAddCustomer =
    RolePermissionService.can(PERMISSIONS.VIEW_CUSTOMER_ROLE) &&
    !companyData.roles.includes(CompanyTypes.CUSTOMER);

  const canViewComment = RolePermissionService.can(
    PERMISSIONS.VIEW_COMPANY_COMMENT
  );

  const canEditAcceptStatus = RolePermissionService.can(
    PERMISSIONS.VIEW_READY_ACCEPT_ORDERS_STATUS
  );

  const canViewContractorStatus = RolePermissionService.can(
    PERMISSIONS.VIEW_CONTRACTOR_STATUS
  );

  const canEditCompanyStatus = RolePermissionService.can(
    PERMISSIONS.EDIT_COMPANY_STATUS
  );

  const changeCompanyStatus = () => {
    changeStatus({
      organizationId: companyData.id,
      status: isLockedStatus ? CompanyStatuses.ACTIVE : CompanyStatuses.LOCKED,
    })
      .unwrap()
      .then(() => {
        setIsLocked((prevLocked) => !prevLocked);
      })
      .catch(() => {});
  };

  const openAddRoleModal = (role: CompanyTypes) => {
    setNewRole(role);
  };

  const closeAddRoleModal = () => {
    setNewRole(null);
  };

  const [updateCompanyDetails] = useUpdateCompanyMutation();

  const handleChangeComment = async (comment: string) => {
    await updateCompanyDetails({
      organizationId: companyData.id,
      body: {
        roles: companyData.roles,
        technicalOpportunitiesCategories:
          companyData.technicalOpportunitiesCategories,
        organizationBankDetails: companyData.organizationBankDetails || {},
        inn: companyData.inn,
        organizationDetails: {
          ...companyData.organizationDetails,
        },
        comment,
      },
    }).unwrap();
  };

  if (
    !canAddContractor &&
    !canAddCustomer &&
    !canViewComment &&
    !canEditAcceptStatus &&
    !canViewContractorStatus &&
    !canEditCompanyStatus
  ) {
    return null;
  }

  return (
    <>
      <Card className={styles.card} noMargin noContentPadding>
        <Stack gap="16px">
          <Typography fontWeight="500">Настройки компании</Typography>
          {/* TODO: Как проверить работоспособность, где апи и модалка? */}
          {canAddContractor && (
            <Button
              className={styles.iconFullWidth}
              onClick={() => openAddRoleModal(CompanyTypes.CONTRACTOR)}
              startIcon={
                <SvgIcon
                  icon={Plus}
                  width="16px"
                  height="16px"
                  strokeColor="#292929"
                />
              }
              variant="text"
              disabled={
                !RolePermissionService.can(PERMISSIONS.EDIT_CONTRACTOR_ROLE)
              }>
              Включить роль исполнителя
            </Button>
          )}
          {/* TODO: Как проверить работоспособность, где апи и модалка? */}
          {canAddCustomer && (
            <Button
              className={styles.iconFullWidth}
              onClick={() => openAddRoleModal(CompanyTypes.CUSTOMER)}
              startIcon={
                <SvgIcon
                  icon={Plus}
                  width="16px"
                  height="16px"
                  strokeColor="#292929"
                />
              }
              variant="text"
              disabled={
                !RolePermissionService.can(PERMISSIONS.EDIT_CUSTOMER_ROLE)
              }>
              Включить роль заказчика
            </Button>
          )}
          {/* TODO: Добавить обработчик после добавления апи */}
          {canEditAcceptStatus && (
            <FormControlLabel
              control={
                <Switch
                  disabled={
                    !RolePermissionService.can(
                      PERMISSIONS.EDIT_READY_ACCEPT_ORDERS_STATUS
                    )
                  }
                />
              }
              label="Готовы принимать заказы"
            />
          )}
          {/* TODO: Добавить обработчик после добавления апи */}
          {canViewContractorStatus && (
            <CompanyComment
              label="Статус исполнителя"
              placeholder="Введите текст статуса"
              value={status}
              onChange={setStatus}
              isDisabled={
                !RolePermissionService.can(PERMISSIONS.EDIT_CONTRACTOR_STATUS)
              }
            />
          )}
          {canEditCompanyStatus && (
            <>
              <FormControlLabel
                control={
                  <Switch
                    checked={isLockedStatus}
                    onChange={changeCompanyStatus}
                    disabled={
                      (!isAdmin && !permissions?.changeCompanyStatus) ||
                      isChangeStatusLoading
                    }
                  />
                }
                label="Ограничить доступ всех контактных лиц"
              />
              {isChangeStatusError && (
                <Typography color="error">
                  {getErrorMessage(
                    isChangeStatusError,
                    'Произошла ошибка при изменении статуса компании'
                  )}
                </Typography>
              )}
            </>
          )}
          {/* TODO: Добавить обработчик после добавления апи */}
          {canViewComment && (
            <CompanyComment
              label="Комментарий к компании"
              placeholder="Введите текст комментария"
              value={companyData.comment || ''}
              onChange={(comment) => handleChangeComment(comment)}
              isDisabled={
                !RolePermissionService.can(PERMISSIONS.EDIT_COMPANY_COMMENT)
              }
            />
          )}
        </Stack>
      </Card>

      <AddRoleModal
        open={!!newRole}
        close={closeAddRoleModal}
        newRole={newRole}
        organizationId={companyData.id}
      />
    </>
  );
};
