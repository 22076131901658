import { styled } from '@mui/material/styles';
import { tooltipClasses, TooltipProps, Tooltip } from '@mui/material';

export const HiddenChips = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} disableFocusListener classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 700,
    padding: 0,
  },
});
