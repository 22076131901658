import IconButton from '@mui/material/IconButton';
import classNames from 'classnames';
import { NewFilter } from 'src/assets/icons';
import { Box, Chip as ChipMui, Stack, Typography } from 'src/components/ui';
import { TabFilters } from 'src/components/ui/new-filters/components/tab-filters';

import { FiltersProps } from 'src/components/ui/new-filters/types';
import { getTabFiltersFromFiltersConfig } from 'src/components/ui/new-filters/utils';
import { Chip, HiddenChips, SortMenu } from './components';
import { FiltersPopover } from './components/filters-popover';
import { SearchForm } from './components/search-form';

import styles from './filters.module.scss';
import { useFilters } from './hooks';

export const NewFilters = <
  KeysSorting,
  AutocompleteItemType = {},
  FilterKeys extends string = string
>(
  props: FiltersProps<KeysSorting, AutocompleteItemType, FilterKeys>
) => {
  const {
    sortList,
    onSort,
    rightBlock: RightBlock,
    isDisabled,
    defaultInput,
    rightBlockWrapper = true,
    showFiltersPopover = true,
    extraActions,
    onFilter,
    searchInputClassnames,
    filterVariantsList,
    autocompleteOptions,
    queryParamsConfig,
    whiteVariant = false,
    onSearch,
  } = props;

  const {
    alert,
    chips,
    chipsRef,
    hiddenChips,
    hiddenChipsCount,
    containerInputRef,
    noActiveSort,
    openPopoverFilter,
    chipsContainerRef,
    inputPadding,
    handleDeleteChip,
    searchInputRef,
    setOpenPopoverFilter,
    handleOnSearch,
    handleSort,
    handleClearFilters,
    updateChipsWithFilters,
    filtersData,
  } = useFilters(props);

  return (
    <div className={styles.filtersContainer}>
      <Typography color="error">{alert}</Typography>
      <div style={{ position: 'relative', overflowX: 'clip' }}>
        <Box
          className={classNames(
            styles.chipWrapper,
            isDisabled && styles.disable
          )}>
          <div
            ref={searchInputRef}
            className={classNames(
              styles.chipAndTabsWrapper,
              searchInputClassnames
            )}>
            {onSearch && (
              <Box className={styles.chipContainer} ref={chipsContainerRef}>
                <Box ref={chipsRef} display="flex">
                  {chips.map((chip, i) => {
                    if (hiddenChips[chip.label]) {
                      return null;
                    }
                    return (
                      <Box
                        key={`${chip.label + i}`}
                        marginLeft="4px"
                        sx={{ '&:first-of-type': { marginLeft: '8px' } }}>
                        <Chip
                          item={chip}
                          onDeleteChip={handleDeleteChip}
                          isDisabled={isDisabled}
                        />
                      </Box>
                    );
                  })}
                </Box>
                {!!hiddenChipsCount && (
                  <HiddenChips
                    arrow={false}
                    placement="bottom-start"
                    title={
                      <Box className={styles.subContainer}>
                        {chips.map((chip, i) => {
                          if (!hiddenChips[chip.label]) {
                            return null;
                          }
                          return (
                            <Box
                              key={`${chip.label + i}`}
                              marginBottom="8px"
                              marginRight="8px">
                              <Chip
                                item={chip}
                                onDeleteChip={handleDeleteChip}
                              />
                            </Box>
                          );
                        })}
                      </Box>
                    }>
                    <ChipMui
                      disabled={!!isDisabled}
                      label={`+${hiddenChipsCount}`}
                      className={styles.chip}
                    />
                  </HiddenChips>
                )}
              </Box>
            )}

            <Box ref={containerInputRef} flexGrow="1">
              {onSearch && (
                <SearchForm
                  isDisabled={isDisabled}
                  defaultInput={defaultInput}
                  onSearch={handleOnSearch}
                  countChips={chips.length}
                  handleClearFilters={handleClearFilters}
                  inputSx={{
                    paddingLeft: `${inputPadding}px !important`,
                    input: {
                      height: '44px !important',
                    },
                  }}
                  autocompleteOptions={autocompleteOptions}
                  whiteVariant={whiteVariant}
                />
              )}
            </Box>
            {filterVariantsList && showFiltersPopover && (
              <FiltersPopover
                config={filterVariantsList}
                filtersData={filtersData ?? {}}
                onFilter={onFilter}
                onClose={() => setOpenPopoverFilter(false)}
                open={openPopoverFilter}
                queryParamsConfig={queryParamsConfig}
                updateChipsWithFilters={updateChipsWithFilters}
              />
            )}
          </div>
          {sortList && onSort && (
            <SortMenu<KeysSorting>
              isDisabled={isDisabled}
              listItems={sortList}
              onSort={handleSort}
              noActive={noActiveSort}
              extraActions={extraActions}
              whiteVariant={whiteVariant}
            />
          )}
          {filterVariantsList && showFiltersPopover && (
            <IconButton
              color={whiteVariant ? 'white' : 'secondary'}
              size="large"
              onClick={() => setOpenPopoverFilter(!isDisabled)}>
              <NewFilter />
            </IconButton>
          )}

          {!!RightBlock && (
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              gap={2}>
              {RightBlock instanceof Function ? (
                <Box
                  className={classNames(
                    rightBlockWrapper && styles.rightBlockWrapper
                  )}>
                  <RightBlock />
                </Box>
              ) : (
                RightBlock
              )}
            </Box>
          )}
          <Stack
            direction="row"
            alignItems="center"
            gap="8px"
            className={styles.tabFiltersWrapper}>
            {filterVariantsList &&
              getTabFiltersFromFiltersConfig(filterVariantsList)
                .filter((tabFilter) => tabFilter.showFilter !== false)
                .map((tabFilter) => (
                  <TabFilters
                    filter={filtersData?.[tabFilter.key]}
                    defaultTabValue={tabFilter.defaultTabValue}
                    updateFilterValue={(value) => {
                      queryParamsConfig?.setQueryParams({
                        [tabFilter.key]: value.value as string,
                      } as Record<FilterKeys, any>);
                      onFilter?.({ ...filtersData, [tabFilter.key]: value });
                    }}
                    filterKey={tabFilter.key}
                    variantsList={tabFilter.variantsList!}
                    whiteVariant={whiteVariant}
                    data={tabFilter.tabsData}
                  />
                ))}
          </Stack>
        </Box>
      </div>
    </div>
  );
};
