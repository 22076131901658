import { FC, ReactNode, useState } from 'react';
import classNames from 'classnames';
import { TFile } from 'src/components/ui/file-upload';
import { Box, Button, Grid, Typography, SvgIcon } from 'src/components/ui';
import { DownloadAllArrow, DownloadArrow } from 'src/assets/icons';
import saveAs from 'file-saver';
import { getErrorMessage } from 'src/store/utils';
import { EmptyFileList, FileItem } from './components';
import styles from './file-list.module.scss';
import { FileItemPreview } from './components/file-item-preview';

export interface IFileListProps {
  value?: TFile[];
  onChange?: (files: TFile[]) => void;
  onDownload?: (fileId: number) => void;
  onRemove?: (fileId: number) => void;
  removeMode?: 'default' | 'soft';
  removeConfirmText?: string;
  className?: string;
  title?: ReactNode;
  subtitle?: ReactNode;
  actions?: ReactNode;
  onDownloadAll?: () => void;
  emptyText?: string;
  showDownloadButton?: boolean;
  useEmptyComponent?: boolean;
  disableDelete?: boolean;
  imagesPreview?: boolean;
  variant?: 'default' | 'compact';
  showCleanButton?: boolean;
  onPreview?: (fileId: number) => void;
}

const variantConfig = {
  default: {
    variant: 'h3',
    lineHeight: undefined,
    marginBottom: '19px',
  },
  compact: {
    variant: 'body2',
    lineHeight: '20px',
    marginBottom: undefined,
  },
} as const;

// TODO: Add key for map

export const FileList: FC<IFileListProps> = ({
  value,
  onChange,
  onDownload,
  onRemove,
  removeMode = 'default',
  removeConfirmText,
  className,
  title,
  subtitle,
  actions,
  onDownloadAll,
  emptyText = 'Файлы не загружены',
  showDownloadButton = true,
  useEmptyComponent = false,
  disableDelete = false,
  imagesPreview = false,
  variant = 'default',
  showCleanButton,
  onPreview,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const onFileChange = (file: TFile) => {
    if (!value) {
      return;
    }

    if (file.isNew) {
      onChange?.(value.filter((item) => item.id !== file.id || !file.deleted));
    } else {
      onChange?.(value.map((item) => (item.id === file.id ? file : item)));
    }
  };

  const handleDownloadAll = async () => {
    if (!onDownloadAll) return;

    setIsLoading(true);
    await onDownloadAll();
    setIsLoading(false);
  };

  const getBottomOffset = () => {
    if (variant === 'default' && !title && !actions && !onDownloadAll) {
      return undefined;
    }

    return variantConfig[variant].marginBottom;
  };

  const handleDownload = async (fileItem: TFile) => {
    if (fileItem.isNew) {
      saveAs(fileItem.file);
    } else {
      await onDownload?.(fileItem.id);
    }
  };

  const handleRemove = async (fileItem: TFile) => {
    if (removeMode !== 'soft' && !fileItem.isNew) {
      if (!onRemove) {
        return;
      }

      try {
        await onRemove(fileItem.id);
      } catch (e) {
        onFileChange?.({ ...fileItem, error: getErrorMessage(e) });
        return;
      }
    }

    onFileChange?.({ ...fileItem, deleted: true });
  };

  const handlePreview = async (fileItem: TFile) => {
    await onPreview?.(fileItem.id);
  };

  return (
    <div className={classNames(styles.fileList, className)}>
      <Box display="flex" justifyContent="space-between" mb={getBottomOffset()}>
        <Typography
          variant={variantConfig[variant].variant}
          lineHeight={variantConfig[variant].lineHeight}>
          {title}
        </Typography>

        <Box display="flex" gap="24px">
          {actions}

          {onDownloadAll && value && value.length > 1 && (
            <Button
              aria-label="download"
              title="Скачать все"
              variant="text"
              loading={isLoading}
              onClick={handleDownloadAll}
              startIcon={<SvgIcon icon={DownloadArrow} strokeWidth="2" />}>
              Скачать все
            </Button>
          )}
        </Box>
      </Box>

      {subtitle}

      {value?.length && value.some((elem) => !elem.deleted) ? (
        <Grid container spacing={imagesPreview ? 2 : 0}>
          {value.map((file) => {
            if (file.deleted) {
              return null;
            }

            return imagesPreview ? (
              <Grid item xs={4} key={file.id}>
                <FileItemPreview
                  file={file}
                  onDownload={handleDownload}
                  onRemove={handleRemove}
                  removeMode={removeMode}
                  onChange={onFileChange}
                  removeConfirmText={removeConfirmText}
                  showDownloadButton={showDownloadButton}
                  disabledDeleteButton={disableDelete}
                  canRemove={!!onRemove}
                />
              </Grid>
            ) : (
              <Grid item xs={12} key={file.id}>
                <FileItem
                  file={file}
                  onDownload={handleDownload}
                  onRemove={handleRemove}
                  removeMode={removeMode}
                  onChange={onFileChange}
                  removeConfirmText={removeConfirmText}
                  showDownloadButton={showDownloadButton}
                  disabledDeleteButton={disableDelete}
                  canRemove={!!onRemove}
                  showCleanButton={showCleanButton}
                  onPreview={handlePreview}
                />
              </Grid>
            );
          })}
        </Grid>
      ) : (
        <div>
          {useEmptyComponent ? (
            <EmptyFileList text={emptyText} />
          ) : (
            <Typography variant="h4">{emptyText}</Typography>
          )}
        </div>
      )}
    </div>
  );
};
