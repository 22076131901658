import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { useResizeObserver } from 'src/hooks';
import { isTags, TTechnicalOpportunitiesCategories } from 'src/models/bid';
import {
  FiltersProps,
  FilterTypes,
  TChip,
  TFilterListValuesMap,
  TSortItem,
  TTreeChipValue,
} from '../types';
import {
  clearFilterValues,
  createSortChip,
  getSearchParameters,
  updateChipsByFiltersData,
  validateSearchQuery,
} from '../utils';

const CHIPS_SPACE_PERCENT = 50; // Процент, который могут занимать chips внутри инпута

export const useFilters = <
  KeysSorting,
  AutocompleteItemType,
  FilterKeys extends string = string
>(
  props: FiltersProps<KeysSorting, AutocompleteItemType, FilterKeys>
) => {
  const {
    onSearch,
    onFilter,
    onSort,
    onCustomTagDelete,
    onClearCustomTags,
    filtersData: filtersDataParam,
    defaultInput,
    sortList,
    searchQueries,
    countSearchQuery = 10,
    defaultFiltersData,
    filterVariantsList,
    queryParamsConfig,
  } = props;

  const [chips, setChips] = useState<Array<TChip>>([]);
  const [chipsWidth, setChipsWidth] = useState(0);
  const [hiddenChips, setHiddenChips] = useState<Record<string, boolean>>({});
  const [noActiveSort, setNoActiveSort] = useState(false);
  const [openPopoverFilter, setOpenPopoverFilter] = useState(false);
  const [alert, setAlert] = useState('');
  // Нужно для отслеживания, находится ли сейчас один из search chip в состоянии редактирования
  const editSearchChipValueRef = useRef<string | null>(null);

  const hiddenChipsCount = Object.keys(hiddenChips).length;

  const containerInputRef = useRef<HTMLDivElement | null>(null);
  const searchInputRef = useRef<HTMLDivElement>(null);
  const chipsRef = useRef<HTMLDivElement>(null);
  const chipsContainerRef = useRef<HTMLDivElement>(null);

  const [filtersData, setFiltersData] = useState(filtersDataParam);

  useEffect(() => {
    setFiltersData(filtersDataParam);
  }, [filtersDataParam]);

  const [inputPadding, setInputPadding] = useState(16);

  useLayoutEffect(() => {
    setInputPadding(
      (chipsContainerRef.current?.getBoundingClientRect().width || 0) + 16
    );
  }, [chips, chipsWidth, hiddenChips, chipsContainerRef.current]);

  useResizeObserver(chipsContainerRef, (entries) => {
    const entry = entries[0];

    if (entry.contentRect.width === chipsWidth) {
      return;
    }

    setChipsWidth(entry.contentRect.width);
    if (editSearchChipValueRef.current !== null) return;
    setHiddenChips({});
  });

  const updateChipsWithFilters = (
    filters: TFilterListValuesMap<FilterKeys>
  ) => {
    setFiltersData(filters);
    const newChips = updateChipsByFiltersData(
      filters,
      chips,
      filterVariantsList
    );
    setChips(newChips);
  };

  useLayoutEffect(() => {
    // Не прячем чипсы во время редактирования одной из них
    if (editSearchChipValueRef.current !== null) return;

    if (!chipsRef.current || !searchInputRef.current) return;

    const containerChildren = chipsRef.current?.children!;
    const inputWidth = searchInputRef.current?.getBoundingClientRect().width;

    const elementWidths: number[] = [];

    for (let i = 0, l = containerChildren.length; i < l; i++) {
      const child = containerChildren[i];
      const clientRect = child.getBoundingClientRect();
      const { width } = clientRect;

      elementWidths.push(width);
    }

    const hiddenChipsMap: Record<string, boolean> = {};
    let maxChipsWidth = Math.floor((inputWidth! / 100) * CHIPS_SPACE_PERCENT);

    chips.forEach((item, index) => {
      const itemWidth = elementWidths[index];
      if (itemWidth <= maxChipsWidth) {
        maxChipsWidth -= itemWidth;
      } else {
        hiddenChipsMap[item.label] = true;
      }
    });

    setHiddenChips(hiddenChipsMap);
  }, [chips, chipsWidth]);

  const handleDeleteChip = useCallback(
    (deleteChip: TChip) => () => {
      editSearchChipValueRef.current = null;
      setChipsWidth(Math.random());

      const newChips = chips.filter(
        (chip) => JSON.stringify(chip) !== JSON.stringify(deleteChip)
      );
      setChips(newChips);
      switch (deleteChip.type) {
        case FilterTypes.SEARCH:
          onSearch?.(getSearchParameters(newChips));
          break;
        case FilterTypes.SORT:
          setNoActiveSort(true);
          onSort?.(null);
          break;
        case FilterTypes.FILTERS:
          if (onFilter) {
            const deletedFilter =
              filtersData![deleteChip.filterKey as FilterKeys];

            if (isTags(deletedFilter?.value)) {
              const filtersDataCopy: TFilterListValuesMap<FilterKeys> = {
                ...filtersData,
              };

              let deletedFilterValueCopy = {
                ...filtersData![deleteChip.filterKey as FilterKeys],
              };

              let filterValue = [
                ...(deletedFilterValueCopy!
                  .value as TTechnicalOpportunitiesCategories),
              ];

              const deletedChipValue = deleteChip.value as TTreeChipValue;

              if (!deletedChipValue.subcategoryId) {
                filterValue = filterValue.filter(
                  (filterValueItem) =>
                    filterValueItem.categoryId !== deletedChipValue.categoryId
                );
              } else {
                const sameSubcategoryChipsCount = chips.filter(
                  (chip) =>
                    chip.value &&
                    typeof chip.value === 'object' &&
                    'subcategoryId' in chip.value &&
                    chip.value.categoryId === deletedChipValue.categoryId
                ).length;

                if (sameSubcategoryChipsCount > 1) {
                  const categoryValueIndex = filterValue.findIndex(
                    (filterValueItem) =>
                      filterValueItem.categoryId === deletedChipValue.categoryId
                  );

                  filterValue[categoryValueIndex].subcategoryIds = filterValue[
                    categoryValueIndex
                  ].subcategoryIds.filter(
                    (subcategory) =>
                      subcategory !== deletedChipValue.subcategoryId
                  );
                } else {
                  filterValue = filterValue.filter(
                    (filterValueItem) =>
                      filterValueItem.categoryId !== deletedChipValue.categoryId
                  );
                }
              }

              deletedFilterValueCopy = {
                ...deletedFilterValueCopy!,
                value: filterValue,
              };

              filtersDataCopy[deleteChip.filterKey as FilterKeys] =
                deletedFilterValueCopy;
              onFilter(filtersDataCopy);
            } else if (Array.isArray(deletedFilter?.value)) {
              // Удаление фильтра типа toggle
              if (
                deletedFilter &&
                filterVariantsList![deletedFilter.key].variantsList!.length ===
                  1
              ) {
                const filtersDataCopy: TFilterListValuesMap<FilterKeys> = {
                  ...filtersData,
                };

                filtersDataCopy[deleteChip.filterKey as FilterKeys] = {
                  ...filtersData![deleteChip.filterKey as FilterKeys],
                  value: ['false'],
                  stringRepresentation: '',
                };
                onFilter(filtersDataCopy);
              } else {
                const filtersDataCopy: TFilterListValuesMap<FilterKeys> = {
                  ...filtersData,
                };

                let deletedFilterValueCopy = {
                  ...filtersData![deleteChip.filterKey as FilterKeys],
                };

                const filterValue = deletedFilterValueCopy!.value as string[];

                deletedFilterValueCopy = {
                  ...deletedFilterValueCopy,
                  value: filterValue.filter(
                    (item) => item !== deleteChip.value
                  ),
                  stringRepresentation: (
                    deletedFilterValueCopy!.stringRepresentation as string[]
                  ).filter((item) => item !== deleteChip.label),
                };

                filtersDataCopy[deleteChip.filterKey as FilterKeys] =
                  deletedFilterValueCopy;
                onFilter(filtersDataCopy);
              }
            } else {
              const filtersDataCopy: TFilterListValuesMap<FilterKeys> = {
                ...filtersData,
              };
              delete filtersDataCopy[deleteChip.filterKey as FilterKeys];
              onFilter(filtersDataCopy);
            }
          }
          break;
        case FilterTypes.CUSTOM_TAG:
          onCustomTagDelete?.({
            label: deleteChip.label,
            value: deleteChip.value ?? '',
          });
          break;
        default:
          break;
      }
      setAlert('');
    },
    [chips, onCustomTagDelete, onFilter, onSearch, onSort]
  );

  const handleClearFilters = useCallback(() => {
    if (filterVariantsList) {
      const newFiltersQueryParams = {} as Record<FilterKeys, any>;
      Object.keys(filterVariantsList).forEach((filterKey) => {
        if (
          filterVariantsList[filterKey as FilterKeys].filterFormType ===
          'toggle'
        ) {
          newFiltersQueryParams[filterKey as FilterKeys] = ['false'];
        } else {
          newFiltersQueryParams[filterKey as FilterKeys] = undefined;
        }
      });
      queryParamsConfig?.setQueryParams(newFiltersQueryParams);
    }

    setChips([]);
    onSearch?.(getSearchParameters([]));
    setNoActiveSort(true);
    onSort?.(null);
    onClearCustomTags?.();
    onFilter?.(clearFilterValues(filtersData, filterVariantsList));
    setAlert('');
  }, [onFilter, onSearch, onSort]);

  const updateEditChipRefValue = (newValue: string | null) => {
    editSearchChipValueRef.current = newValue;
    setChipsWidth(Math.random());
  };

  const handleSearchChipEdit = (oldValue: string, newValue: string) => {
    editSearchChipValueRef.current = null;
    setChipsWidth(Math.random());

    const parametersSearchQuery: string[] = [...searchQueries!];

    if (!newValue) {
      onSearch?.(parametersSearchQuery);
      return;
    }

    const oldValueIndex = parametersSearchQuery.findIndex(
      (value) => value === oldValue
    );

    parametersSearchQuery[oldValueIndex] = newValue;

    onSearch?.(parametersSearchQuery);
  };

  const handleOnSearch = useCallback(
    (searchQuery: string, isClearAll = false) => {
      if (isClearAll) {
        onSearch?.([]);
        setChips((prevState) =>
          prevState.filter((chip) => chip.type !== FilterTypes.SEARCH)
        );
        return;
      }

      if (chips.length >= countSearchQuery) {
        setAlert(
          `Максимальное количество параметров в строке для поиска ${countSearchQuery}`
        );
        return;
      }

      const newChip: TChip = {
        type: FilterTypes.SEARCH,
        label: searchQuery,
        hidden: true,
      };

      if (defaultInput) {
        onSearch?.([newChip.label]);
        return;
      }

      if (validateSearchQuery(newChip, chips)) {
        const newChips = [newChip, ...chips];
        setChips(newChips);
        const parametersSearchQuery: string[] = [];
        newChips.forEach((chip) => {
          if (chip.type === FilterTypes.SEARCH) {
            parametersSearchQuery.unshift(chip.label);
          }
        });
        onSearch?.(parametersSearchQuery);
      }
      setOpenPopoverFilter(false);
    },
    [chips, countSearchQuery, defaultInput, onSearch]
  );

  const handleSort = useCallback(
    (sortParameters: TSortItem<KeysSorting>) => {
      let sortChip = createSortChip<KeysSorting>(sortParameters);
      let isChangeSortDirection = false;
      const chipsWithoutSort = chips.filter((chip) => {
        if (chip.type === FilterTypes.SORT && sortChip.label === chip.label) {
          isChangeSortDirection = true;
          sortChip = {
            ...sortChip,
            hidden: chip.hidden,
          };
        }
        return chip.type !== FilterTypes.SORT;
      });

      if (isChangeSortDirection) {
        setChips([...chipsWithoutSort, sortChip]);
      } else {
        setChips([...chipsWithoutSort, sortChip]);
      }

      setNoActiveSort(false);
      onSort?.({
        field: sortParameters.key,
        direction: sortParameters.direction,
      });
    },
    [chips, onSort]
  );

  useEffect(() => {
    editSearchChipValueRef.current = null;
  }, [filtersData, sortList]);

  // TODO check
  useLayoutEffect(() => {
    if (defaultFiltersData || filtersData) {
      let newChips: TChip[] = [];

      if (defaultFiltersData?.searchQueries?.length) {
        newChips = defaultFiltersData.searchQueries.map((item) => ({
          hidden: false,
          label: item,
          value: item,
          type: FilterTypes.SEARCH,
          // Поля нужные для функционала редактирования значения чипа
          onSearchChipEdit: handleSearchChipEdit,
          updateEditChipRefValue,
          editChipRefValue: editSearchChipValueRef.current,
        }));
      }

      if (!defaultFiltersData?.searchQueries?.length && searchQueries) {
        newChips = searchQueries.map((item) => ({
          hidden: false,
          label: item,
          value: item,
          type: FilterTypes.SEARCH,
          // Поля нужные для функционала редактирования значения чипа
          onSearchChipEdit: handleSearchChipEdit,
          updateEditChipRefValue,
          editChipRefValue: editSearchChipValueRef.current,
        }));
      }

      if (defaultFiltersData?.sort && sortList) {
        newChips = [
          ...newChips,
          {
            direction: defaultFiltersData.sort.order,
            hidden: false,
            label:
              sortList.find(
                (sortItem) => sortItem.key === defaultFiltersData.sort?.field
              )?.label ?? '',
            type: FilterTypes.SORT,
          },
        ];
      }

      if (filtersData) {
        newChips = updateChipsByFiltersData(
          filtersData,
          newChips,
          filterVariantsList
        );
        setChips(newChips);
      }

      if (defaultFiltersData?.customTags) {
        const customChips = defaultFiltersData.customTags.map(
          (customFilter) => ({
            hidden: false,
            label: customFilter.label,
            value: customFilter.value,
            type: FilterTypes.CUSTOM_TAG,
          })
        );
        newChips = [...newChips, ...customChips];
      }

      setChips(newChips);
    }
  }, [
    defaultFiltersData,
    sortList,
    filtersData,
    // Эта зависимость нужна, чтобы в поисковой строке можно было одновременно
    // редактировать не больше одного чипа
    editSearchChipValueRef.current,
  ]);

  useEffect(() => {
    if (searchQueries && searchQueries.length) {
      const initSearchChips = searchQueries.map((query) => ({
        type: FilterTypes.SEARCH,
        label: query,
        hidden: true,
      }));
      setChips((prev) => {
        const clearQueries = initSearchChips.filter((q) =>
          validateSearchQuery(q, prev)
        );
        return [...clearQueries, ...prev];
      });
    }
  }, []);

  return {
    chips,
    hiddenChipsCount,
    hiddenChips,
    noActiveSort,
    openPopoverFilter,
    containerInputRef,
    searchInputRef,
    chipsRef,
    alert,
    chipsContainerRef,
    inputPadding,
    handleDeleteChip,
    handleSort,
    handleOnSearch,
    setOpenPopoverFilter,
    handleClearFilters,
    updateChipsWithFilters,
    filtersData,
  };
};
