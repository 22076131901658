import { FC, lazy, Suspense } from 'react';

import { PageLoader } from 'src/components/ui';

const AllRequests = lazy(
  () =>
    import(
      /* webpackChunkName: 'AllRequests' */
      /* webpackMode: "lazy" */
      `./all-requests`
    )
);

export const LazyCustomerAllRequests: FC = () => (
  <Suspense fallback={<PageLoader />}>
    <AllRequests />
  </Suspense>
);
