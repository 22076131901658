import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from 'src/store/utils';
import {
  ISendMessageParams,
  IUserAndGroupListQueryParams,
  IUserAndGroupsResult,
  TMessagesResult,
} from 'src/api/communications/models';

export const communicationChatApi = createApi({
  reducerPath: 'communicationChatApi',
  baseQuery,
  tagTypes: ['Chats'],
  endpoints: (builder) => ({
    getUserAndGroupList: builder.query<
      IUserAndGroupsResult,
      IUserAndGroupListQueryParams
    >({
      query: (params) => ({
        url: 'users-service/api/v1/user/search-for-messaging',
        method: 'GET',
        params,
      }),
      providesTags: ['Chats'],
    }),
    // ISendEmailMessageResult
    sendMessage: builder.mutation<void, ISendMessageParams>({
      query: ({ ...body }) => ({
        url: 'aggregator/api/v1/message/send',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Chats'],
    }),
    getMessagesByApplicationId: builder.query<TMessagesResult, string>({
      query: (applicationId) =>
        `aggregator/api/v1/message/application/${applicationId}`,
    }),
  }),
});

export const {
  useLazyGetUserAndGroupListQuery,
  useGetUserAndGroupListQuery,
  useSendMessageMutation,
  useGetMessagesByApplicationIdQuery,
} = communicationChatApi;
